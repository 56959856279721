import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const videos = {
    uploadVideos: null,
    freeVideos: null,
    rideVideos: null,
    archiveVideos: null,
    purchaseVideo: null,
    failedVideo: null,
    currentProduct: null
}

//types
const GET_ALL_UPLOAD_VIDEOS = "GET_ALL_UPLOAD_VIDEOS"
const GET_ALL_FREE_VIDEOS = "GET_ALL_FREE_VIDEOS"
const GET_ALL_RIDE_VIDEOS = "GET_ALL_RIDE_VIDEOS"
const GET_ALL_ARCHIVE_VIDEOS = "GET_ALL_ARCHIVE_VIDEOS"
const GET_PURCHASE_VIDEO = "GET_PURCHASE_VIDEO"
const GET_FAILED_VIDEO = "GET_FAILED_VIDEO"
const GET_CURRENT_PROD = "GET_CURRENT_PROD"
const CLEAR_CURRENT_PROD = "CLEAR_CURRENT_PROD"

//reducer
export default function videosReducer(state = videos, action){

    switch(action.type){
        
        case GET_ALL_UPLOAD_VIDEOS:
            return {...state, 
                uploadVideos: action.payload,
            }

        case GET_ALL_FREE_VIDEOS:
            return {...state, 
                freeVideos: action.payload,
            }
        case GET_ALL_RIDE_VIDEOS:
            return {...state, 
                rideVideos: action.payload,
            }
        case GET_ALL_ARCHIVE_VIDEOS:
            return {...state, 
                archiveVideos: action.payload,
            }
        case GET_PURCHASE_VIDEO:
            return {...state, 
                purchaseVideo: action.payload,
            }
        case GET_FAILED_VIDEO:
            return {...state, 
                failedVideo: action.payload,
            }
        case GET_CURRENT_PROD:
            return {...state, 
                currentProduct: action.payload,
            }
        case CLEAR_CURRENT_PROD:
            return {...state, 
                currentProduct: null,
            }
        default:
            return state
    }
}

//actions
export const GetAllVideos = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0020ProductService.searchForMember",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            var dispatchCommand = "";
            if (inputData.product_type === "1"){
                dispatchCommand = GET_ALL_UPLOAD_VIDEOS;
            }
            else if (inputData.product_type === "2"){
                dispatchCommand = GET_ALL_RIDE_VIDEOS;
            }
            else if (inputData.product_type === "3"){
                dispatchCommand = GET_ALL_FREE_VIDEOS;
            }

            dispatch({
                type: dispatchCommand,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const GetArchiveVideos = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0020ProductService.searchArchives",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            
            dispatch({
                type: GET_ALL_ARCHIVE_VIDEOS,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const GetPurchaseVideo = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0020ProductService.searchProductByPurchaseId",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            
            dispatch({
                type: GET_PURCHASE_VIDEO,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
        
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const GetFailedVideo = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0020ProductService.search",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            
            dispatch({
                type: GET_FAILED_VIDEO,
                payload: response.data.data[0].output[0]
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 


export const searchAnyProductByProductId = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0020ProductService.searchAnyProductByProductId",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            
            dispatch({
                type: GET_CURRENT_PROD,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const ClearCurrentProduct = () => (dispatch, getState) => {
    dispatch({type: CLEAR_CURRENT_PROD});
    dispatch({type: CommonTypes.CLEAR_ERROR});
}


