import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingCom from "../../components/LoadingCom";

const UploadSchedule = () => {
  const initData = useSelector((state) => state.initData);
  const { t } = useTranslation();

  const scheduleHasData = (schedule) => {
    var len = 0;
    if (schedule.length > 0) {
      schedule.map((pl, i) => (len += pl.length));
      return len > 0;
    } else {
      return false;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="h-text-dark">{t("videosSchedule.title")}</div>
            </div>
          </div>

          <div className="row schedule-table">
            {initData.isLoading ? (
              <LoadingCom />
            ) : (
              <>
                {initData.productsSchedule &&
                scheduleHasData(initData.productsSchedule) ? (
                  initData.productsSchedule.map((pl, i) => (
                    <div className="col schedule-col" key={"sched_" + i}>
                      <table>
                        <tbody>
                          <tr>
                            <th
                              className={
                                i === 2 ? "current" : "schedule-col-month-" + i
                              }
                            >
                              {i === 2 ? (
                                <span className="inner">
                                  {t("dates.month_name", {
                                    date: new Date(
                                      new Date().setMonth(
                                        new Date().getMonth() + (i - 2)
                                      )
                                    ),
                                  })}
                                </span>
                              ) : (
                                t("dates.month_name", {
                                  date: new Date(
                                    new Date().setMonth(
                                      new Date().getMonth() + (i - 2)
                                    )
                                  ),
                                })
                              )}
                            </th>
                          </tr>

                          {initData.productsSchedule &&
                            initData.productsSchedule[i]?.map((p, subI) => (
                              <tr
                                key={
                                  "sched_day_" +
                                  subI +
                                  "_" +
                                  p.product_id +
                                  "_" +
                                  p.series_id
                                }
                              >
                                <td
                                  className={
                                    i === 2 ? "schedule-td-current" : ""
                                  }
                                >
                                  {i === 2 ? (
                                    <div className="b-dot">
                                      <span className="current">
                                        <span className="text-blue ">■</span>
                                        {t("dates.month_day", {
                                          date: new Date(p.release_from_day),
                                        })}
                                        <span className="br text-sm">
                                          {p.series_title
                                            ? p.series_title +
                                              ": " +
                                              p.product_title
                                            : p.product_title}
                                        </span>
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <span className="text-blue ">■</span>
                                      {t("dates.month_day", {
                                        date: new Date(p.release_from_day),
                                      })}
                                      <span className="br text-sm">
                                        {p.series_title
                                          ? p.series_title
                                          : p.product_title}
                                      </span>
                                      <div className="b-dot"></div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <div className="alert alert-info">
                      {t("videosSchedule.noData")}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadSchedule;
