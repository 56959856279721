
const SystemConsts = {
	ContextPath: '/echan',
	Api:{
		Host: window.location.origin, 
		AuthUrl : '/api/auth.api',
		DataUrl : '/api/data.api',
		UploadFileUrl: '/api/upload.api',
		DownloadUrl: '/api/download.api'
    },
	Pay :{
		SuccessUrl: window.location.origin + '/member/paysuccess/{PURCHASE_ID}', 
		FailUrl:  window.location.origin + '/member/payfail/{PRODUCT_ID}', 
		BillReturnUrl: window.location.origin + '/member/billreturn', 
	},
	SystemLanguage: "ja",
	Video :{
		AppId: '58479'	
	},
	subscriptionSuccessStatus: "active",
	error500: "code 500",
	errorInvalidCurrentPassword: "現在のパスワードが正しくありません。",
	errorLoginidOrPasswordIncorrect: "loginidOrPasswordIncorrect",
	subscription_validation_error: "subscription_validation_error"
};

export default SystemConsts
