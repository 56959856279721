import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import authReducer from './useAuthRedux'
import passwordReducer from './usePasswordRedux'
import commonReducer from './CommonReducer'
import questionnaireReducer from "./useQuestionnairesRedux"
import initDataReducer from './useInitDataRedux'
import noticesReducer from './useNoticesRedux'
import videosReducer from './useVideosRedux'
import paymentReducer from './usePaymentRedux'
import onlineProdReducer from './useOnlineProdRedux'
import seriesReducer from './useSeriesRedux'

const rootReducer = combineReducers({
    loginData: authReducer,
    passwordChange: passwordReducer,
    common: commonReducer,
    questionnarie: questionnaireReducer,
    initData: initDataReducer,
    notices: noticesReducer,
    videos: videosReducer,
    payment: paymentReducer,
    onLineProds: onlineProdReducer,
    series: seriesReducer
})

//To use with "Redux DevTools" chrome extension 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){

    const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(thunk)))
    return store;
}