import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SystemConsts from "../common/constants/SystemConsts";
import useAuth from "../auth/useAuth";
import { doThumbnail } from "../common/utils/Utils";

const url =
  SystemConsts.Api.Host +
  SystemConsts.ContextPath +
  SystemConsts.Api.DownloadUrl +
  "?path=p_";
const PayMark = (props) => {
  const auth = useAuth();
  const { t } = useTranslation();

  if (props.video.product_type === "2" || props.isArchive) {
    //2:騎乗動画 or Archive
    if (props.video.is_paid === "1") {
      return (
        <div className="video_img-top-right-no-free">{t("common.paid")}</div>
      );
    } else {
      return (
        <div className="video_img-top-right-no-free">
          &#165;{props.video.price}
        </div>
      );
    }
  } else {
    if (auth.isAuthenticated) {
      if (
        auth.user?.subscriptionStatus !== SystemConsts.subscriptionSuccessStatus
      ) {
        return <div className="video_img-top-right-no-free">&#165;</div>;
      } else {
        return <></>;
      }
    } else {
      return <div className="video_img-top-right-no-free">&#165;</div>;
    }
  }
};
const FreeMark = () => {
  const { t } = useTranslation();
  return <div className="video_img-top-right-free">{t("common.free")}</div>;
};

const VideoCard = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const Thumbnail = (props) => {
    if (props.video?.product_id)
      return (
        <img
          className="video-img"
          src={
            url +
            props.video.product_id +
            ".jpg&name=p_" +
            props.video.product_id +
            ".jpg "
          }
          alt={props.video.thumbnail_name}
          onClick={() => handleImgClick(props.video, props.isArchive)}
          onError={(e) => handleImgError(e)}
        />
      );
  };

  const handleImgError = (e) => {
    doThumbnail(e, props, handleImgClick);
    return true;
  };

  const handleImgClick = (product, isArchive) => {
    let pathName = "";

    if (product.product_type === "3" || product.price === "0") {
      //Free video
      pathName = "/member/playfreevideo";
    } else {
      if (product.product_type === "1" && !isArchive) {
        //Uploaded video (配信動画)
        pathName = "/member/playuploadvideo";
      } else {
        //その他（決済が必要）
        pathName = "/member/playpayvideo";
      }
    }

    history.push({
      pathname: pathName,
      state: { product: product },
    });
  };

  return (
    <div className="card mb-3 shadow-sm card-gray">
      <div className="video_img-container img-wrapper">
        <Thumbnail video={props.video} isArchive={props.isArchive} />
        {parseInt(props.video.price) > 0 ? (
          <PayMark video={props.video} isArchive={props.isArchive} />
        ) : (
          <FreeMark />
        )}
      </div>
      <div className="card-body">
        <p className="card-text">{props.video.product_title}</p>
        <div className="text-muted">
          {props.collectionLength && props.collectionLength > 4
            ? t("dates.month_day", {
                date: new Date(props.video.release_from_day),
              }) +
              "〜" +
              t("dates.month_day", {
                date: new Date(props.video.release_end_day),
              })
            : t("dates.fullDateShort", {
                date: new Date(props.video.release_from_day),
              }) +
              "〜" +
              t("dates.fullDateShort", {
                date: new Date(props.video.release_end_day),
              })}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
