import React from "react";
import { useSelector } from "react-redux";
import Logo from "../../components/Logo";

const Header = () => {
  const initData = useSelector((state) => state.initData);
  return (
    <header>
      <div className="navbar">
        <div className="container d-flex justify-content-between">
          <a href="/member/" className="navbar-brand d-flex align-items-center">
            {initData.logo && <Logo logo={initData.logo} />}

            <div className="header-text d-none d-sm-block d-md-block d-lg-block d-xl-block">
              &nbsp;&nbsp;{initData.title}
            </div>
            <div className="header-text d-block d-sm-none">
              &nbsp;&nbsp;{initData.titleShort}
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
