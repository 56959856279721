import { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { useSelector } from "react-redux";
import SystemConsts from "../common/constants/SystemConsts";
import Error500 from "../pages/error/Error500";

export default function MainRoute({ component: Component, ...rest }) {
    const auth = useAuth();
    const location = useLocation();
    const error = useSelector((store) => store.common.error);
    const loginDataError = useSelector((store) => store.loginData.error);

    // Scroll to top if path changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    
    // Show loading page while user is authenticating
    if (auth.isAuthenticating() && !error && !loginDataError){
        return <div>Loading...</div>
    }

    // Show conexion error
    if ( error?.includes(SystemConsts.error500) || loginDataError?.includes(SystemConsts.error500)){
        return <Error500 />
    }

    return (
        <Route {...rest}>
        {(auth.user?.password_initial_flag === "1") ? (
            <Redirect to={{ pathname: "/member/resetpw" , state: { from: location }}} />
        ) : (
            <Component />
        )}
        </Route>
    
    );
}
