import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    onlineProducts: null,
    paidOnlineProduct: null,
    freePaymentProductId: null
}

//types
const GET_ALL_ONLINE_PRODS = "GET_ALL_ONLINE_PRODS"
const GET_PAID_ONLINE_PROD = "GET_PAID_ONLINE_PROD"
const CLEAR_PAID_ONLINE_PROD = "CLEAR_PAID_ONLINE_PROD"
const SET_FREE_PAYMENT = "SET_FREE_PAYMENT"


//reducer
export default function onlineProdReducer(state = initialData, action){

    switch(action.type){
        
        case GET_ALL_ONLINE_PRODS:
            return {...state, 
                onlineProducts: action.payload,
                paidOnlineProduct: null,
                freePaymentProductId: null
            }
        case GET_PAID_ONLINE_PROD:
            return {...state, 
                paidOnlineProduct: action.payload
            }
        case CLEAR_PAID_ONLINE_PROD:
            return {...state, 
                paidOnlineProduct: null,
                freePaymentProductId: null
            }

        case SET_FREE_PAYMENT:
            return {...state, 
                freePaymentProductId: action.payload
            }
        default:
            return state
    }
}

//actions
export const GetAllOnlineProds = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0030OnlineProductService.findOnlineProductsForMember",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: GET_ALL_ONLINE_PRODS,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const GetPaidOnlineProd = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0030OnlineProductService.findOnlineProductsForMember",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: GET_PAID_ONLINE_PROD,
                payload: response.data.data[0].output[0]
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const ClearPaidOnlineProd = () => (dispatch, getState) => {
    dispatch({type: CLEAR_PAID_ONLINE_PROD});
    dispatch({type: CommonTypes.CLEAR_ERROR});
}

export const SetFreePayment = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "m0030OnlineProductService.SetFreePayment",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: SET_FREE_PAYMENT,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 