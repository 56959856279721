import React from "react";
import { useTranslation } from "react-i18next";
import TextboxCom from "../../components/TextboxCom";

const ResetPasswordForm = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="inputPasswordNew">
            {t("resetPassword.currentPassword")}
          </label>
        </div>
        <div className="col-md-8">
          <TextboxCom
            type="password"
            autoFocus
            name="current_user_password"
            placeholder={t("resetPassword.currentPassword")}
            value={props.formValues.current_user_password}
            onChange={props.handleChange}
            formerrors={props.formErrors}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="newPassword">{t("resetPassword.newPassword")}</label>
        </div>
        <div className="col-md-8">
          <TextboxCom
            type="password"
            name="user_password"
            placeholder={t("resetPassword.newPassword")}
            value={props.formValues.user_password}
            onChange={props.handleChange}
            formerrors={props.formErrors}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="newPasswordConfirm">
            {t("resetPassword.newPasswordConfirm")}
          </label>
        </div>
        <div className="col-md-8">
          <TextboxCom
            type="password"
            name="user_password_confirm"
            placeholder={t("resetPassword.newPasswordConfirm")}
            value={props.formValues.user_password_confirm}
            onChange={props.handleChange}
            formerrors={props.formErrors}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
