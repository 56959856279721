import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ModalInfoPanel = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={() => props.setShow(false)}
      animation={false}
    >
      <Modal.Body>
        <div className="alert alert-success">
          <strong>{props.title}</strong>
          <br />
          {props.text}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary btn-lg"
          onClick={() => props.setShow(false)}
        >
          {t("common.close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfoPanel;
