import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/useAuth";
import { Link } from "react-router-dom";
import ModalInfoPanel from "../../components/ModalInfoPanel";
import ResetPasswordDialog from "../resetPassword/ResetPasswordModal";
import { useHistory } from "react-router";

const Menu = (props) => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [showPwResetModal, setShowPwResetModal] = useState(false);
  const [showInfoPw, setShowInfoPw] = useState(false);
  const history = useHistory();

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const handlePwChanged = (success) => {
    setShowPwResetModal(false);
    if (success === true) {
      setShowInfoPw(true);
    }
  };

  const handleLogout = () => {
    auth.logout();
    history.push("/member");
  };

  const getUserPermissionName = () => {
    var permission = "";

    if (auth.user?.member_auth1_flag === "1") {
      permission = t("menu.user_auth1");
    }

    if (auth.user?.member_auth2_flag === "1") {
      if (permission !== "") permission += "／";
      permission += t("menu.user_auth2");
    }

    if (auth.user?.member_auth3_flag === "1") {
      if (permission !== "") permission += "／";
      permission += t("menu.user_auth3");
    }

    if (auth.user?.member_auth4_flag === "1") {
      if (permission !== "") permission += "／";
      permission += t("menu.user_auth4");
    }

    return permission;
  };

  return (
    <>
      <ResetPasswordDialog
        showPwResetModal={showPwResetModal}
        handleClose={handlePwChanged}
      />
      <ModalInfoPanel
        show={showInfoPw}
        setShow={setShowInfoPw}
        title={t("resetPassword.modalTitle")}
        text={t("resetPassword.success")}
      />
      <section className="bg-dark">
        <div className="container">
          <div className="row">
            <input
              type="checkbox"
              name="navToggle"
              id="navToggle"
              className="nav-toggle"
            />
            <label htmlFor="navToggle" className="btn-burger">
              <span className="icon"></span>
            </label>

            <nav className="nav">
              <ul className="nav-list">
                <li>
                  <Link to="/member/" className="btn-home">
                    {t("menu.home")}
                  </Link>
                </li>

                <li>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-region-select dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("menu.language")}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item"
                        onClick={() => handleChangeLang("ja")}
                      >
                        日本語
                      </span>
                      <span
                        className="dropdown-item"
                        onClick={() => handleChangeLang("en")}
                      >
                        English
                      </span>
                      {/* <span
                        className="dropdown-item"
                        onClick={() => handleChangeLang("es")}
                      >
                        Español
                      </span> */}
                    </div>
                  </div>
                </li>

                {!auth.isAuthenticated && (
                  <li>
                    <Link to="/member/newmember" className="btn-registration">
                      {t("menu.registration")}
                    </Link>
                  </li>
                )}

                {auth.isAuthenticated && (
                  <li>
                    <span
                      className="btn-passwd"
                      onClick={() => {
                        setShowPwResetModal(true);
                      }}
                    >
                      {t("menu.passwordChange")}
                    </span>
                  </li>
                )}

                {!auth.isAuthenticated && (
                  <li>
                    <Link to="/member/login" className="btn-login">
                      {t("menu.login")}
                    </Link>
                  </li>
                )}

                {auth.isAuthenticated && (
                  <li>
                    <span className="login-user">
                      {auth.user?.user_name +
                        "　(" +
                        getUserPermissionName() +
                        ") "}
                    </span>
                  </li>
                )}

                {auth.isAuthenticated && (
                  <li>
                    <button
                      type="button"
                      className="btn btn-logout"
                      onClick={() => handleLogout()}
                    >
                      {t("menu.logout")}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Menu;
