import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    subscriptionPrice: "",
}

//types
const GET_SUBSCRIPTION_PRICE = "GET_SUBSCRIPTION_PRICE"


//reducer
export default function paymentReducer(state = initialData, action){

    switch(action.type){
        
        case GET_SUBSCRIPTION_PRICE:
            return {...state, subscriptionPrice: action.payload}

        default:
            return state
    }
}

//actions
export const SearchSubscriptionPrice = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "stripeService.findSubscriptionPrice",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: GET_SUBSCRIPTION_PRICE,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 