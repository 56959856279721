import React, { useEffect, useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { SaveNewPassword, ClearState } from "../../redux/usePasswordRedux";
import { Modal } from "react-bootstrap";
import SystemConsts from "../../common/constants/SystemConsts";

const ResetPasswordModal = (props) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    current_user_password: "",
    user_password: "",
    user_password_confirm: "",
    user_id: auth.user?.user_id,
  };
  const passwordError = useSelector((store) => store.passwordChange.error);
  const passwordSaveResult = useSelector(
    (store) => store.passwordChange.result
  );
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //Listen for API Error
  useEffect(() => {
    if (
      passwordError !== null &&
      passwordError === SystemConsts.errorInvalidCurrentPassword
    ) {
      setError(t("resetPassword.errors.invalid_current_password"));
    } else {
      setError(passwordError);
    }
  }, [passwordError, t]);

  const handleClose = () => {
    dispatch(ClearState());
    setFormValues(initialValues);
    setFormErrors({});
    props.handleClose(false);
  };

  //Listen for API Save result
  useEffect(() => {
    if (passwordSaveResult && Object.keys(passwordSaveResult).length > 0) {
      if (props.showPwResetModal === true) {
        const initValues = {
          current_user_password: "",
          user_password: "",
          user_password_confirm: "",
          user_id: auth.user?.user_id,
        };
        props.handleClose(true);
        dispatch(ClearState());
        setFormValues(initValues);
      }
    }
  }, [passwordSaveResult, props, dispatch, auth.user?.user_id]);

  const validate = (values) => {
    let errors = {};

    if (!values.current_user_password) {
      errors.current_user_password = t(
        "resetPassword.errors.currentPasswordRequired"
      );
    } else {
      if (
        values.current_user_password.length < 8 ||
        values.current_user_password.length > 16
      ) {
        errors.current_user_password = t(
          "resetPassword.errors.password_between8_16"
        );
      }
    }

    if (!values.user_password) {
      errors.user_password = t("resetPassword.errors.newPasswordRequired");
    } else {
      if (values.user_password.length < 8 || values.user_password.length > 16) {
        errors.user_password = t("resetPassword.errors.password_between8_16");
      }
    }

    if (!values.user_password_confirm) {
      errors.user_password_confirm = t(
        "resetPassword.errors.newPasswordConfirmRequired"
      );
    } else {
      if (
        values.user_password_confirm.length < 8 ||
        values.user_password_confirm.length > 16
      ) {
        errors.user_password_confirm = t(
          "resetPassword.errors.password_between8_16"
        );
      }
    }

    if (
      values.user_password_confirm &&
      values.user_password &&
      values.user_password_confirm !== values.user_password
    ) {
      errors.user_password_confirm = t("resetPassword.errors.samePasswords");
    }

    setFormErrors(errors);
    return errors;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    let errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      dispatch(SaveNewPassword(formValues));
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        show={props.showPwResetModal}
        onHide={handleClose}
        animation={false}
      >
        <form onSubmit={(e) => handleResetPassword(e)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("resetPassword.modalTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ResetPasswordForm
              formValues={formValues}
              handleChange={handleChange}
              formErrors={formErrors}
            />
            {error && (
              <div className="alert alert-warning">
                <h6 style={{ color: "red" }}>{error}</h6>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary btn-lg"
              onClick={() => handleClose(false)}
            >
              {t("common.close")}
            </button>
            <button type="submit" className="btn btn-primary btn-lg">
              {t("common.ok")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
