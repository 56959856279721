import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const initData = useSelector((state) => state.initData);

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <footer className="text-muted">
      <div className="container">
        <p className="float-right">
          <Link onClick={() => topFunction()} to="/member/">
            {t("common.backtotop")}
          </Link>
        </p>
        <p>{initData.titleFooter}</p>
      </div>
      <button
        onClick={() => topFunction()}
        id="gototopBtn"
        title={t("menu.goToTop")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          //class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </button>
    </footer>
  );
};

export default Footer;
