import React from "react";
import SystemConsts from "../common/constants/SystemConsts";

const VideoPlayerCom = (props) => {
  const getSrcUrl = () => {
    let url =
      "https://player.vimeo.com/video/" +
      props.videoId +
      "?badge=0&autopause=0&player_id=0&portrait=false&title=false&loop=false&byline=false&app_id=" +
      SystemConsts.Video.AppId;
    return url;
  };
  return (
    <div style={{ padding: "56.44% 0 0 0", position: "relative" }}>
      <iframe
        src={getSrcUrl()}
        frameBorder="0"
        allow={{ autoplay: true, fullscreen: true, pictureInPicture: true }}
        allowFullScreen={true}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        title={props.title}
      ></iframe>
    </div>
  );
};

export default VideoPlayerCom;
