import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function PublicRoute({ component: Component, ...rest }) {
    const auth = useAuth();
    const location = useLocation();
 
    if (auth.isAuthenticating()){
        return <div>Loading...</div>
    }

    return (
        <Route {...rest}>
        {(auth.isAuthenticated) ? (
            <Redirect to={{ pathname: "/member/" , state: { from: location }}} />
        ) : (
            <Component />
        )}
        </Route>
    
    );
}
