import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SystemConsts from "../../common/constants/SystemConsts";
import CommonTypes from "../../redux/CommonTypes";

const SystemErrorPanel = (props) => {
  const { t } = useTranslation();
  const error = useSelector((store) => store.common.error);
  const [openPanel, setOpenPanel] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseLoadingPanel = () => {
    setOpenPanel(false);
    //Clear error
    dispatch({ type: CommonTypes.CLEAR_ERROR });
  };

  useEffect(() => {
    setOpenPanel(error !== null);

    if (error === SystemConsts.subscription_validation_error) {
      setErrorMessage(t("login.subscriptionCheckError"));
    } else {
      setErrorMessage(error);
    }
  }, [error, t]);

  return (
    <Modal
      size="lg"
      show={openPanel}
      onHide={handleCloseLoadingPanel}
      animation={false}
    >
      <Modal.Body>
        <div className="alert alert-danger">
          <strong>{t("common.error")}</strong>
          <br />
          {errorMessage}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary btn-lg"
          onClick={handleCloseLoadingPanel}
        >
          {t("common.close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SystemErrorPanel;
