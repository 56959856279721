import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {format as formatDate,isDate} from "date-fns";
import { ja, es, enUS } from "date-fns/locale";
import japaneseLocale from './locales/ja/locale.json';
import englishLocale from './locales/en/locale.json';
import spanishLocale from './locales/es/locale.json';
import SystemConsts from './common/constants/SystemConsts';

const locales = { ja, es, enUS };

// the translations
const resources = {
    ja: {
        translation: japaneseLocale
    },
    en: {
        translation: englishLocale
    },
    es: {
        translation: spanishLocale
    }
};

const savedLang = localStorage.getItem("lang");

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: (savedLang)?savedLang:SystemConsts.SystemLanguage, 
        fallbackLng: SystemConsts.SystemLanguage, 
        interpolation: {
            escapeValue: false, // react already safes from xss
            format: (value, format, lng) => {
				if (isDate(value)) {
					const locale = locales[lng==="en"?"enUS":lng];

                    //YYYY/MM/DD (jap) YYYY/DD/MM (en)
					if (format === "short")
						return formatDate(value, "P", { locale });
                    //YYYY年MM月DD日 (jap) YYYY/DD/MM (en)
                    if (format === "jap")
						return formatDate(value, lng==="ja"?"yyyy年 MMM do (EEEEEE)": "P (EEEEEE)", { locale });
                    //例：４月９日 (jap) Apr 9th (en)
                    if (format === "month_day")
						return formatDate(value, lng==="es"?'MMM dd':lng==="ja"?'MMMdo':'MMM do', { locale });
                    //例：４月 (jap) April (en)
                    if (format === "month_name")
						return formatDate(value, "LLLL", { locale });
                    //例：火 (jap) Tu (en)
                    if (format === "weekday")
                        return formatDate(value, "EEEEEE", { locale });
                    //HH:mm
                    if (format === "time")
                        return formatDate(value, "H:mm", { locale });
					//YYYY年MM月DD日 HH:mm (jap) YYYY/DD/MM HH:mm(en)
                    if (format === "datetime")
                        return formatDate(value, "P H:mm", { locale });

					return formatDate(value, format, { locale });
				}

				return value;
			}

        },
        react: {
            //wait: true,
            useSuspense: true,
        }
    });

export default i18n;
