import React from "react";
import PlayVideo from "./PlayVideo";
import { useLocation } from "react-router-dom";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import Footer from "../../modules/footer/Footer";

//決済動画
const PlayPayVideo = (props) => {
  const location = useLocation();

  const product = location.state.product;
  return (
    <>
      <Header />
      <Menu />
      <PlayVideo product={product} />
      <Footer />
    </>
  );
};

export default PlayPayVideo;
