import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function PrivateRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  const location = useLocation();

  return (
    <Route {...rest}>
      {(auth.isAuthenticated) ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: "/member/login" , state: { from: location }}} />
      )}
    </Route>
   
  );
}