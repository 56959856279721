import React from "react";
import VideoPlayerCom from "../../components/VideoPlayerCom";
import { useTranslation } from "react-i18next";

const PlayVideo = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container viewmore-content">
        <div className="row">
          <div className="col-md-12">　</div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h2>{t("playContent.title")}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-end">
            {t("playContent.releaseDate") +
              ": " +
              t("dates.fullDateShort", {
                date: new Date(props.product.release_from_day),
              }) +
              "～" +
              t("dates.fullDateShort", {
                date: new Date(props.product.release_end_day),
              })}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <strong>{props.product.product_title}</strong>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">{props.product.product_note}</div>
        </div>

        <div className="row video-player">
          <div className="col-md-12">
            <VideoPlayerCom
              videoId={props.product.video_id}
              title={props.product.product_title}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayVideo;
