import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NoticeDialog = (props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.handleClose(false);
  };

  const textContainsHtml = (text) => {
    var a = document.createElement("div");
    a.innerHTML = text;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  };

  const formatNoticeText = (text) => {
    if (!text) return "";
    if (textContainsHtml(text)) {
      return text;
    } else {
      return text.replace(/\n/g, "<br />");
    }
  };

  return (
    <div>
      <Modal size="lg" show={props.show} onHide={handleClose} animation={false}>
        <div className="notice-dialog">
          <Modal.Header closeButton className="notice-dialog-header">
            <Modal.Title>
              <div>
                <strong className="notice-dialog">{props.notice?.title}</strong>
              </div>
              <div>
                <h6>
                  {t("notices.release_from_day") +
                    ": " +
                    props.notice?.release_from_day}
                </h6>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="notice-dialog"
              dangerouslySetInnerHTML={{
                __html: formatNoticeText(props.notice?.content),
              }}
            ></div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary btn-lg"
              onClick={handleClose}
            >
              {t("common.close")}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default NoticeDialog;
