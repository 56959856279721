import { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, checkSessionToken, checkSavedToken, logout } from "../redux/useAuthRedux";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.loginData);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const  setErrorHandler = (error) => {
    setUser(null);
    setIsAuthenticated(false);
  }

  useEffect(() => {
    if (loginData?.loginUser){
      if (!localStorage.getItem("user")){
        const tokenInfo = {
          user_id: loginData.loginUser.user.user_id, 
          rememberPw: loginData.loginUser.savelogin , 
          token: loginData.loginUser.token};
        localStorage.setItem("user", JSON.stringify(tokenInfo));
      }
      
      setUser(loginData.loginUser.user);
      setIsAuthenticated(true);
    }
    else{
      setUser(null);
      try {
        const token = JSON.parse(localStorage.getItem("user"));
        if (token){
          if (!token.rememberPw || token.rememberPw === "0") {
            dispatch(checkSessionToken(token.user_id, token.token, setErrorHandler));
          } else {
            dispatch(checkSavedToken(token.user_id, token.token, setErrorHandler));
          }
        }
      } catch (e) {
          //Invalid token
          setUser(null);
          setIsAuthenticated(false);
      }
    }
  }, [loginData, dispatch]);
  
  const contextValue = {
    user,
    login(user_id, user_password, savelogin, setLoginUser, setError){
      const values = {user_id, user_password, savelogin};
      dispatch(login(values, setError, setLoginUser));
    },
    logout() {
      const tokenInfo = JSON.parse(localStorage.getItem("user"));
      const data = {token: tokenInfo.token, user}
      dispatch(logout(data));

      localStorage.removeItem("user");
      setUser(null);
      setIsAuthenticated(false);
    },
    isAuthenticated,
    isAuthenticating(){
      const tokenInfo = JSON.parse(localStorage.getItem("user"));
      return (tokenInfo && !user)
    },
    refresh(){
      try {
        const token = JSON.parse(localStorage.getItem("user"));
        if (token){
          if (!token.rememberPw || token.rememberPw === "0") {
            dispatch(checkSessionToken(token.user_id, token.token, setErrorHandler));
          } else {
            dispatch(checkSavedToken(token.user_id, token.token, setErrorHandler));
          }
        }
      } catch (e) {
          //Invalid token
          setUser(null);
          setIsAuthenticated(false);
      }
    }
  };  

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;