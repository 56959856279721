import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    title: "ESJ Education Channel",
    titleShort: "ESJ",
    titleFooter: "© 2021 Equitation School Japan",
    notices: null,
    language: "ja",
    logo: "",
    productsSchedule: null,
    videos: null,
    freeVideos: null,
    rideVideos: null,
    archiveVideos: null,
    onlineProds: null,
    currentOnlineProd: null,
    seriesVideos: null,
    recommendedProduct: null,
    isLoading: true,
    stripe_public_key: null,
    systemSettings: null
}

//types
const GET_INIT_DATA = "GET_INIT_DATA"
const REFRESH_NOTICES = "REFRESH_NOTICES"
const REFRESH_ONLINECOURSES = "REFRESH_ONLINECOURSES"


//reducer
export default function initDataReducer(state = initialData, action){

    switch(action.type){
        
        case GET_INIT_DATA:
            return {...state, 
                title: action.payload.headerTitle, 
                titleShort: action.payload.headerTitleShort, 
                titleFooter: action.payload.footerTitle,
                notices: action.payload.notices,
                language: action.payload.language,
                logo: action.payload.logo,
                user_id: action.payload.user_id,
                productsSchedule: action.payload.productsSchedule,
                videos: action.payload.videos,
                freeVideos: action.payload.freeVideos,
                rideVideos: action.payload.rideVideos,
                archiveVideos: action.payload.archiveVideos,
                onlineProds: action.payload.onlineProds,
                currentOnlineProd: action.payload.currentOnlineProd,
                seriesVideos: action.payload.seriesVideos,
                recommendedProduct: action.payload.recommendedProduct,
                stripe_public_key: action.payload.stripe_public_key,
                isLoading: false,
                systemSettings: action.payload.systemSettings
            }
        case REFRESH_NOTICES:
            return {...state, 
                notices: action.payload.notices,
                user_id: action.payload.user_id,
            }
        case REFRESH_ONLINECOURSES:
            return {...state, 
                onlineProds: action.payload.onlineProds,
                currentOnlineProd: action.payload.currentOnlineProd,
                seriesVideos: action.payload.seriesVideos,
                recommendedProduct: action.payload.recommendedProduct
            }

        default:
            return state
    }
}

//actions
export const SearchInitData = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "memberSystemService.getInitData",
            mode: 0,
            input: inputData,
            },
        ],
        };

    //dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: GET_INIT_DATA,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const RefreshNotices = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "memberSystemService.getNotices",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: REFRESH_NOTICES,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const UpdateNoticeAsRead = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
          {
            api: "t0041NoticeUsersService.save",
            mode: 0,
            input: inputData,
          },
        ],
      };

      dispatch({type: CommonTypes.CLEAR_ERROR});

      await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output){

                let refreshNoticesData = { language: getState().initData.language, user_id: getState().initData.user_id };

                dispatch(RefreshNotices(refreshNoticesData));
            }
            else{
                let errorMessage = "System Error";
                if (response.data.data[0].errors && response.data.data[0].errors[0].messageKey){
                    errorMessage = response.data.data[0].errors[0].messageKey;
                }

                dispatch({
                    type: CommonTypes.GET_DATA_ERROR,
                    payload: errorMessage
                })
            }

        })
        .catch((err ) => {
            //Error
            console.log("Axios err: ", err)
            let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: errorMessage
            })
        })
        .finally(() => {
        });
    
} 

export const RefreshOnlineCourses = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "memberSystemService.refreshOnlineCourses",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: REFRESH_ONLINECOURSES,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 