import React from "react";
import { useTranslation } from "react-i18next";

const LoadingCom = () => {
  const { t } = useTranslation();
  return (
    <>
      <span>{t("common.loading")}</span>
      <div className="spinner-border text-muted"></div>
    </>
  );
};

export default LoadingCom;
