import React from "react";

const TextareaCom = (props) => {
  return (
    <>
      <textarea
        id={props.name}
        rows={props.rows}
        cols={props.cols}
        type={props.type ? props.type : "text"}
        className={
          props.formerrors && props.formerrors[props.name]
            ? "form-control is-invalid"
            : "form-control"
        }
        onInvalid={(e) => e.target.setCustomValidity("パスワードは必須です")}
        onInput={(e) => e.target.setCustomValidity("")}
        {...props}
        style={{ resize: "none" }}
      />
      <div className="invalid-feedback">
        {props.formerrors && props.formerrors[props.name]}
      </div>
    </>
  );
};

export default TextareaCom;
