import React, { useEffect } from "react";
import { useParams } from "react-router";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useTranslation } from "react-i18next";
import { GetPurchaseVideo } from "../../redux/useVideosRedux";
import { UploadUserVideoAuthorization } from "../../redux/useAuthRedux";
import { RefreshOnlineCourses } from "../../redux/useInitDataRedux";
import { useDispatch, useSelector } from "react-redux";
import PlayVideo from "../playvideo/PlayVideo";
import SystemErrorPanel from "../main/SystemErrorPanel";

export default function PaySuccess(props) {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const purchaseProduct = useSelector((state) => state.videos.purchaseVideo);
  const loginUser = useSelector((state) => state.loginData?.loginUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && id !== "" && id !== "0") {
      const searchConds = {
        purchase_id: id,
        language: i18n.language,
      };
      dispatch(GetPurchaseVideo(searchConds));
    }
  }, [dispatch, i18n.language, id]);

  //If user pay the subscription on video click,
  //and the user has not the 配信動画 authorization
  //upload user authorization
  useEffect(() => {
    if (purchaseProduct?.product_id === "0") {
      if (loginUser?.user?.member_auth1_flag === "0") {
        dispatch(UploadUserVideoAuthorization());
      }
    }
  }, [purchaseProduct, loginUser, dispatch]);

  //If free online course has been bought
  //refresh initial data
  useEffect(() => {
    if (
      purchaseProduct?.product_type === "99" &&
      purchaseProduct?.price === "0"
    ) {
      const conds = {
        language: i18n.language,
      };
      dispatch(RefreshOnlineCourses(conds));
    }
  }, [purchaseProduct, dispatch, i18n.language]);

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content unauthorized-video-warn">
        <div className="row">
          <div className="col-md-10">
            <div className="alert alert-success">
              <strong>{t("payment.paySuccessTitle")}</strong>{" "}
              {!purchaseProduct ? (
                <></>
              ) : purchaseProduct?.product_id === "0" ? (
                t("payment.paySuccessSubscription")
              ) : purchaseProduct?.product_type === "99" ? (
                t("payment.paySuccessProductOnlineCourse", {
                  courseTitle: purchaseProduct?.product_title,
                })
              ) : (
                t("payment.paySuccessProductVideo", {
                  videoTitle: purchaseProduct?.product_title,
                })
              )}
              {purchaseProduct &&
                purchaseProduct?.product_type !== "99" &&
                purchaseProduct?.product_id !== "0" && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
            </div>

            {purchaseProduct?.video_id && (
              <div>
                <PlayVideo product={purchaseProduct} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
