import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
import SystemConsts from "../common/constants/SystemConsts";
import { useTranslation } from "react-i18next";

//配信動画
export default function UploadVideoRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const subscriptionProduct = {
    product_id: "0",
    product_title: t("payment.subscriptionTitle"),
    product_note: t("payment.subscriptionDesc"),
    price: "",
  };

  return (
    <Route {...rest}>
    {
      (auth.isAuthenticated) ? 
        (
          (auth.user.subscriptionStatus === SystemConsts.subscriptionSuccessStatus)?
          (<Component />)
          :
          (<Redirect to={{ pathname: "/member/payment" , state: {product: subscriptionProduct, from: location  }}} />)
      ) 
      : 
      (
        <Redirect to={{ pathname: "/member/login" , state: { from: location  }}} />
      )
}

      
    </Route>
   
  );
}

