import React from "react";

const TextboxCom = (props) => {
  return (
    <>
      <input
        id={props.name}
        type={props.type ? props.type : "text"}
        className={
          props.formerrors && props.formerrors[props.name]
            ? "form-control is-invalid"
            : "form-control"
        }
        onInvalid={(e) => e.target.setCustomValidity("パスワードは必須です")}
        onInput={(e) => e.target.setCustomValidity("")}
        {...props}
      />
      <div className="invalid-feedback">
        {props.formerrors && props.formerrors[props.name]}
      </div>
    </>
  );
};

export default TextboxCom;
