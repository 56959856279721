import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextboxCom from "../../components/TextboxCom";
import {
  SearchSystemQuestionnaire,
  ClearState,
} from "../../redux/useQuestionnairesRedux";
import { signin } from "../../redux/useAuthRedux";
import SystemConsts from "../../common/constants/SystemConsts";
import { useHistory } from "react-router";
import Logo from "../../components/Logo";
import TextareaCom from "../../components/TextareaCom";

const NewMemberRegister = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const savedLang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : SystemConsts.SystemLanguage;
  const initData = useSelector((state) => state.initData);
  const systemError = useSelector((store) => store.common.error);
  const questionnarieRedux = useSelector(
    (store) => store.questionnarie.questionnaire
  );

  const initialValues = {
    user_id: "",
    user_name: "",
    user_password: "",
    user_password_confirm: "",
    user_mail_address: "",
    member_auth1_flag: "0",
    member_auth2_flag: "0",
    member_auth3_flag: "0",
    member_auth4_flag: "0",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [questionnarie, setQuestionnarie] = useState({});

  useEffect(() => {
    //Search for the last questionnairy on component load
    dispatch(SearchSystemQuestionnaire());

    //Clear the state on component unmount
    return () => {
      dispatch(ClearState());
    };
  }, [dispatch]);

  //Listen for API Error
  useEffect(() => {
    setError(systemError);
  }, [systemError]);

  //Listen for API result
  useEffect(() => {
    if (questionnarieRedux && Object.keys(questionnarieRedux).length > 0) {
      setQuestionnarie(questionnarieRedux);

      //Update form values (questionnarie radio components)
      if (
        questionnarieRedux.questions?.length > 0 &&
        !formValues["optradioyesno" + questionnarieRedux.questions[0].id]
      ) {
        let values = formValues;
        questionnarieRedux.questions.map((q) =>
          q.questionnaire_type.toString() === "0"
            ? (values["optradioyesno" + q.id] = "1")
            : (values["optradioyesno" + q.id] = "")
        );
        setFormValues(values);
      }
    }
  }, [questionnarieRedux, formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked ? "1" : "0" });
  };

  const validate = (values) => {
    let errors = {};

    if (!values.user_id) {
      errors.user_id = t("newMemberRegister.errors.user_id_required");
    } else {
      if (values.user_id.length < 6 || values.user_id.length > 16) {
        errors.user_id = t("newMemberRegister.errors.user_id_between6_16");
      }
    }

    if (!values.user_name) {
      errors.user_name = t("newMemberRegister.errors.user_name_required");
    }

    if (!values.user_password) {
      errors.user_password = t(
        "newMemberRegister.errors.user_password_required"
      );
    } else {
      if (values.user_password.length < 8 || values.user_password.length > 16) {
        errors.user_password = t(
          "newMemberRegister.errors.user_password_between8_16"
        );
      }
    }

    if (!values.user_password_confirm) {
      errors.user_password_confirm = t(
        "newMemberRegister.errors.user_password_confirm_required"
      );
    } else {
      if (
        values.user_password_confirm.length < 8 ||
        values.user_password_confirm.length > 16
      ) {
        errors.user_password_confirm = t(
          "newMemberRegister.errors.user_password_between8_16"
        );
      }
    }

    if (
      values.user_password_confirm &&
      values.user_password &&
      values.user_password_confirm !== values.user_password
    ) {
      errors.user_password_confirm = t(
        "newMemberRegister.errors.samePasswords"
      );
    }

    if (!values.user_mail_address) {
      errors.user_mail_address = t(
        "newMemberRegister.errors.user_mail_address_required"
      );
    } else {
      if (!validateMailFormat(values.user_mail_address)) {
        errors.user_mail_address = t("newMemberRegister.errors.incorrect_mail");
      }
    }

    if (
      values.member_auth1_flag === "0" &&
      values.member_auth2_flag === "0" &&
      values.member_auth3_flag === "0" &&
      values.member_auth4_flag === "0"
    ) {
      errors.member_auth4_flag = t(
        "newMemberRegister.errors.member_auth_required"
      );
    }

    //FreeText elements validations
    var inputs = document.getElementsByTagName("textarea");
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].value === "") {
        errors[inputs[i].name] = t(
          "newMemberRegister.errors.question_required"
        );
      } else {
        if (inputs[i].value.length > inputs[i].maxLength) {
          errors[inputs[i].name] = t(
            "newMemberRegister.errors.question_between1_xx",
            { value: inputs[i].maxLength }
          );
        }
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const validateMailFormat = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSave = (e) => {
    e.preventDefault();
    let errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      let questionnaire = [];
      questionnarieRedux.questions.map((q) =>
        questionnaire.push({
          questionnaire_id: q.questionnaire_id,
          questionnaire_seq: q.questionnaire_seq,
          answer: formValues["optradioyesno" + q.id],
        })
      );

      let values = { ...formValues, questionnaire };

      dispatch(signin(values, setError, handleSuccess));
    }
  };

  const handleSuccess = (newUser) => {
    console.log(newUser);
    if (newUser.user.member_auth1_flag === "1") {
      const product = {
        product_id: "0",
        product_title: t("payment.subscriptionTitle"),
        product_note: t("payment.subscriptionDesc"),
        price: "",
      };
      history.push({
        pathname: "/member/payment",
        state: { product: product },
      });
    }
  };

  return (
    <div className="body-newmember">
      <div className="text-center">
        <form className="form-newmember" onSubmit={(e) => handleSave(e)}>
          {initData.logo && <Logo logo={initData.logo} />}
          <br />
          <span className="newmember-header-text">{initData.title}</span>
          <h1 className="h3 mb-3 font-weight-normal title-newmember">
            {t("newMemberRegister.title")}
          </h1>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="user_id">{t("newMemberRegister.user_id")}</label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                autoFocus
                name="user_id"
                maxLength="16"
                placeholder={t("newMemberRegister.user_id")}
                value={formValues.user_id}
                onChange={handleChange}
                formerrors={formErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="user_name">
                {t("newMemberRegister.user_name")}
              </label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                name="user_name"
                maxLength="40"
                placeholder={t("newMemberRegister.user_name")}
                value={formValues.user_name}
                onChange={handleChange}
                formerrors={formErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="user_password">
                {t("newMemberRegister.user_password")}
              </label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                type="password"
                name="user_password"
                maxLength="16"
                placeholder={t("newMemberRegister.user_password")}
                value={formValues.user_password}
                onChange={handleChange}
                formerrors={formErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="user_password_confirm">
                {t("newMemberRegister.user_passwordConfirm")}
              </label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                type="password"
                name="user_password_confirm"
                maxLength="16"
                placeholder={t("newMemberRegister.user_passwordConfirm")}
                value={formValues.user_password_confirm}
                onChange={handleChange}
                formerrors={formErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="user_mail_address">
                {t("newMemberRegister.user_mail_address")}
              </label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                name="user_mail_address"
                maxLength="1024"
                placeholder={t("newMemberRegister.user_mail_address")}
                value={formValues.user_mail_address}
                onChange={handleChange}
                formerrors={formErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="member_auth">
                {t("newMemberRegister.member_auth")}
              </label>
            </div>
            <div className="col-md-8">
              <div className="checkbox mb-3">
                <input
                  type="checkbox"
                  id="member_auth1_flag"
                  name="member_auth1_flag"
                  value="member_auth1_flag"
                  checked={formValues.member_auth1_flag === "1"}
                  onChange={handleChecked}
                />
                <label htmlFor="member_auth1_flag">
                  &nbsp;{t("newMemberRegister.member_auth1_flag")}
                </label>
              </div>
            </div>

            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="checkbox mb-3">
                <input
                  type="checkbox"
                  id="member_auth2_flag"
                  name="member_auth2_flag"
                  value="member_auth2_flag"
                  checked={formValues.member_auth2_flag === "1"}
                  onChange={handleChecked}
                />
                <label htmlFor="member_auth2_flag">
                  &nbsp;{t("newMemberRegister.member_auth2_flag")}
                </label>
              </div>
            </div>

            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="checkbox mb-3">
                <input
                  type="checkbox"
                  id="member_auth3_flag"
                  name="member_auth3_flag"
                  value="member_auth3_flag"
                  checked={formValues.member_auth3_flag === "1"}
                  onChange={handleChecked}
                />
                <label htmlFor="member_auth3_flag">
                  &nbsp;{t("newMemberRegister.member_auth3_flag")}
                </label>
              </div>
            </div>

            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="checkbox mb-3">
                <input
                  type="checkbox"
                  id="member_auth4_flag"
                  name="member_auth4_flag"
                  value="member_auth4_flag"
                  checked={formValues.member_auth4_flag === "1"}
                  onChange={handleChecked}
                />
                <label htmlFor="member_auth4_flag">
                  &nbsp;{t("newMemberRegister.member_auth4_flag")}
                </label>
              </div>
            </div>
            {formErrors.member_auth4_flag && (
              <>
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <div className="errorlabel">
                    {formErrors.member_auth4_flag}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label>{t("newMemberRegister.questionnaire")}</label>
            </div>
            <div className="col-md-8">
              {questionnarie?.questions &&
                questionnarie.questions.map((question) => (
                  <div className="question" key={"question_" + question.id}>
                    <label key={question.id}>
                      {question["question_" + savedLang]
                        ? question["question_" + savedLang]
                        : question["question_ja"]}
                    </label>
                    <br />

                    {question.questionnaire_type.toString() === "1" && (
                      <TextareaCom
                        name={"optradioyesno" + question.id}
                        rows={Math.ceil(question.questionnaire_size / 20)}
                        cols="50"
                        maxLength={question.questionnaire_size}
                        value={formValues["optradioyesno" + question.id]}
                        onChange={handleChange}
                        formerrors={formErrors}
                      />
                    )}

                    {question.questionnaire_type.toString() === "0" && (
                      <>
                        <div className="form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={"radioyes" + question.id}
                          >
                            <input
                              type="radio"
                              className="form-check-input"
                              id={"radioyes" + question.id}
                              name={"optradioyesno" + question.id}
                              value="1"
                              checked={
                                formValues["optradioyesno" + question.id] ===
                                "1"
                              }
                              onChange={handleChange}
                            />
                            {t("common.yes")}
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={"radiono" + question.id}
                          >
                            <input
                              type="radio"
                              className="form-check-input"
                              id={"radiono" + question.id}
                              name={"optradioyesno" + question.id}
                              value="2"
                              checked={
                                formValues["optradioyesno" + question.id] ===
                                "2"
                              }
                              onChange={handleChange}
                            />
                            {t("common.no")}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {error && (
            <div className="alert alert-warning">
              <h6 style={{ color: "red" }}>{error}</h6>
            </div>
          )}

          <button
            className="btn btn-lg btn-primary btn-block reset-pw-button"
            type="submit"
          >
            {t("newMemberRegister.save")}
          </button>

          <div className="logout-advice">
            <Link to="/member/">{t("common.backtotop")}</Link>
          </div>

          <p className="mt-5 mb-3 text-muted">{initData.titleFooter}</p>
        </form>
      </div>
    </div>
  );
};

export default NewMemberRegister;
