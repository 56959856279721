import React, { useEffect, useState } from "react";
import ResetPasswordForm from "../../modules/resetPassword/ResetPasswordForm";
import { Link } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SaveNewPassword } from "../../redux/usePasswordRedux";
import { RefreshLoginUserData } from "../../redux/useAuthRedux";
import Logo from "../../components/Logo";

const ResetPassword = () => {
  const auth = useAuth();
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const systemError = useSelector((store) => store.common.error);
  const resetPwResult = useSelector((store) => store.passwordChange.result);

  const intialValues = {
    current_user_password: "",
    user_password: "",
    user_password_confirm: "",
    user_id: auth.user?.user_id,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const initData = useSelector((state) => state.initData);

  //Listen for API Error
  useEffect(() => {
    setError(systemError);
  }, [systemError]);

  //Listen for API result
  useEffect(() => {
    if (resetPwResult && Object.keys(resetPwResult).length > 0) {
      //Refresh login user from Redux context
      dispatch(RefreshLoginUserData(resetPwResult));
    }
  }, [resetPwResult, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    let errors = {};

    if (!values.current_user_password) {
      errors.current_user_password = t(
        "resetPassword.errors.currentPasswordRequired"
      );
    } else {
      if (
        values.current_user_password.length < 8 ||
        values.current_user_password.length > 16
      ) {
        errors.current_user_password = t(
          "resetPassword.errors.password_between8_16"
        );
      }
    }

    if (!values.user_password) {
      errors.user_password = t("resetPassword.errors.newPasswordRequired");
    } else {
      if (values.user_password.length < 8 || values.user_password.length > 16) {
        errors.user_password = t("resetPassword.errors.password_between8_16");
      }
    }

    if (!values.user_password_confirm) {
      errors.user_password_confirm = t(
        "resetPassword.errors.newPasswordConfirmRequired"
      );
    } else {
      if (
        values.user_password_confirm.length < 8 ||
        values.user_password_confirm.length > 16
      ) {
        errors.user_password_confirm = t(
          "resetPassword.errors.password_between8_16"
        );
      }
    }

    if (
      values.user_password_confirm &&
      values.user_password &&
      values.user_password_confirm !== values.user_password
    ) {
      errors.user_password_confirm = t("resetPassword.errors.samePasswords");
    }

    setFormErrors(errors);
    return errors;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    let errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      dispatch(SaveNewPassword(formValues));
    }
  };

  return (
    <div className="body-resetpw">
      <div className="text-center">
        <form className="form-resetpw" onSubmit={(e) => handleResetPassword(e)}>
          {initData.logo && <Logo logo={initData.logo} />}
          <span className="resetpw-header-text">{initData.title}</span>
          <h1 className="h3 mb-3 font-weight-normal title-resetpw">
            {t("resetPassword.resetPassword")}
          </h1>

          <ResetPasswordForm
            formValues={formValues}
            handleChange={handleChange}
            formErrors={formErrors}
          />

          {error && (
            <div className="alert alert-warning">
              <h6 style={{ color: "red" }}>{error}</h6>
            </div>
          )}

          <button
            className="btn btn-lg btn-primary btn-block reset-pw-button"
            type="submit"
          >
            {t("resetPassword.update")}
          </button>

          <div className="logout-advice">
            <Link to="/member" onClick={() => auth.logout()}>
              {t("resetPassword.logout")}
            </Link>
          </div>

          <p className="mt-5 mb-3 text-muted">{initData.titleFooter}</p>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
