import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import VideoCard from "../../components/VideoCard";
import { Link } from "react-router-dom";
import LoadingCom from "../../components/LoadingCom";

const FreeVideos = () => {
  const initData = useSelector((state) => state.initData);
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <div className="movie">{t("freeVideos.title")}</div>
        </div>
        {initData.freeVideos?.length > 0 && (
          <div className="col-md-2">
            <Link to="/member/freevideos" className="view_more">
              {t("common.view_more")}
            </Link>
          </div>
        )}
      </div>

      <div className="row">
        {initData.isLoading ? (
          <LoadingCom />
        ) : initData.freeVideos?.length > 0 ? (
          initData.freeVideos.map((v, i) => (
            <div
              className={
                "col-md-" +
                Math.round(12 / initData.systemSettings.showFreeVideosCount)
              }
              key={"video_" + i}
            >
              <VideoCard
                video={v}
                collectionLength={initData.freeVideos.length}
              />
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <div className="alert alert-info">{t("common.noVideos")}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default FreeVideos;
