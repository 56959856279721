import React, { useEffect } from "react";
import { useParams } from "react-router";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetFailedVideo } from "../../redux/useVideosRedux";

export default function PayFail(props) {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const purchaseVideo = useSelector((state) => state.videos.failedVideo);

  useEffect(() => {
    if (id && id !== "" && id !== "0") {
      const searchConds = {
        product_id: id,
        language: i18n.language,
      };
      dispatch(GetFailedVideo(searchConds));
    }
  }, [dispatch, i18n.language, id]);

  return (
    <>
      <Header />
      <Menu />
      <div className="container viewmore-content unauthorized-video-warn">
        <div className="row">
          <div className="col-md-10">
            <div className="alert alert-danger">
              <strong>{t("payment.payFailTitle")}</strong>{" "}
              {id !== "0"
                ? t("payment.payFailProductVideo", {
                    videoTitle: purchaseVideo?.product_title,
                  })
                : t("payment.payFailSubscription")}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
