import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function PaymentRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  const location = useLocation();

  return (
    <Route {...rest}>
      {(!auth.isAuthenticated) ? (
        <Redirect to={{ pathname: "/member/login" , state: { from: location }}} />
      ) 
      /*
      : location.state.product.is_paid === ""? 
      (<Redirect to={{ pathname: "/" , state: {product: location.state.product, from: location  }}} />)
      */
      :
      (
        <Component />
      )}
    </Route>
   
  );
 
  
}
