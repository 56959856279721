import React from "react";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import PlayVideo from "./PlayVideo";

const PlayFreeVideo = (props) => {
  return (
    <>
      <Header />
      <Menu />
      <PlayVideo product={props.location.state.product} />;
      <Footer />
    </>
  );
};

export default PlayFreeVideo;
