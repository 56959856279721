import React, { useEffect } from "react";
import Archives from "../../modules/archives/Archives";
import FreeVideos from "../../modules/freeVideos/FreeVideos";
import RideVideos from "../../modules/rideVideos/RideVideos";
import Menu from "../../modules/menu/Menu";
import Notices from "../../modules/notices/Notices";
import OnlineCourses from "../../modules/onlineCourses/OnlineCourses";
import RecommendedContent from "../../modules/recommendedContent/RecommendedContent";
import Series from "../../modules/series/Series";
import UploadedVideos from "../../modules/uploadedVideos/UploadedVideos";
import UploadSchedule from "../../modules/uploadSchedule/UploadSchedule";
import Header from "../../modules/header/Header";
import Footer from "../../modules/footer/Footer";
import SystemErrorPanel from "./SystemErrorPanel";
import { useSelector } from "react-redux";
import SystemConsts from "../../common/constants/SystemConsts";
import Error500 from "../error/Error500";

const Main = () => {
  const loginUser = useSelector((state) => state.loginData.loginUser);
  const error = useSelector((store) => store.common.error);

  useEffect(() => {
    window.onscroll = function () {
      var mybutton = document.getElementById("gototopBtn");
      if (!mybutton) return;
      if (
        document.body.scrollTop > 25 ||
        document.documentElement.scrollTop > 25
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    };
  });

  return (
    <>
      {error !== null && error.includes(SystemConsts.error500) ? (
        <Error500 />
      ) : (
        <>
          <SystemErrorPanel />
          <Header />
          <Menu />

          <section className="section-bold">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <Notices />
                </div>
                <div className="col-md-5">
                  <OnlineCourses />
                </div>
              </div>
            </div>
          </section>

          <RecommendedContent />

          <section className="bg-light section-bold">
            <div className="container">
              <UploadedVideos />
              <Archives />
              {loginUser && loginUser.user?.member_auth3_flag === "1" && (
                <RideVideos />
              )}
            </div>
          </section>

          <section className="bg-dark-image section-bold">
            <Series />
          </section>

          <section className="section-bold">
            <UploadSchedule />
          </section>

          <section className="bg-light section-bold">
            <div className="container">
              <FreeVideos />
            </div>
          </section>

          <Footer />
        </>
      )}
    </>
  );
};

export default Main;
