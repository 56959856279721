import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    notices: null
}

//types
const GET_ALL_NOTICES = "GET_ALL_NOTICES"


//reducer
export default function noticesReducer(state = initialData, action){

    switch(action.type){
        
        case GET_ALL_NOTICES:
            return {...state, 
                notices: action.payload,
            }

        default:
            return state
    }
}

//actions
export const GetAllNotices = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
            {
            api: "memberSystemService.getAllNotices",
            mode: 0,
            input: inputData,
            },
        ],
        };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: GET_ALL_NOTICES,
                payload: response.data.data[0].output
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 

export const UpdateNoticeAsRead = (inputData) => async (dispatch, getState) => {

    var ctx = {
        data: [
          {
            api: "t0041NoticeUsersService.save",
            mode: 0,
            input: inputData,
          },
        ],
      };

      dispatch({type: CommonTypes.CLEAR_ERROR});

      await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output){

                let refreshNoticesData = { 
                    language: getState().initData.language, 
                    user_id: getState().initData.user_id, 
                    target_type_search: ["1", "3"] };

                dispatch(GetAllNotices(refreshNoticesData));
            }
            else{
                let errorMessage = "System Error";
                if (response.data.data[0].errors && response.data.data[0].errors[0].messageKey){
                    errorMessage = response.data.data[0].errors[0].messageKey;
                }

                dispatch({
                    type: CommonTypes.GET_DATA_ERROR,
                    payload: errorMessage
                })
            }

        })
        .catch((err ) => {
            //Error
            console.log("Axios err: ", err)
            let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: errorMessage
            })
        })
        .finally(() => {
        });
    
} 
