export const doThumbnail = (imgElement, props, handleImgClick) => {
  var className = handleImgClick ? "thumbnail_title" : "thumbnail_title_serie";

  var div = document.createElement("div");
  div.className = className;

  //Remove previous thumbnail elements:
  const elements =
    imgElement.target.parentElement.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }

  var video;
  if (!props.video) {
    video = props.product;
  } else {
    video = props.video;
  }

  div.innerHTML = video.product_title;
  imgElement.target.parentElement.appendChild(div);
  imgElement.target.src = "/member/assets/image/Thumbnail_background.svg";
  imgElement.target.onerror = null;
  imgElement.target.parentElement.classList.remove("img-wrapper");

  if (handleImgClick) {
    div.onclick = function () {
      handleImgClick(video, props.isArchive);
    };

    imgElement.target.onmouseover = function () {
      div.style.fontSize = "larger";
    };
    imgElement.target.onmouseout = function () {
      div.style.fontSize = "medium";
    };

    div.onmouseover = function () {
      div.style.fontSize = "larger";
    };
    div.onmouseout = function () {
      div.style.fontSize = "medium";
    };
  }
};
