import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const OnlineCourseElement = (props) => {
  const onLineProd = props.onLineProd;
  const { t } = useTranslation();
  const history = useHistory();

  const handleRowClick = (e) => {
    const onlineProd = JSON.parse(e.currentTarget.getAttribute("data"));

    if (onlineProd.is_paid === "1") {
      alert(t("onlineProducts.courseIsPaid"));
    } else if (onlineProd.entry_on_time === "0") {
      alert(t("onlineProducts.courseIsExpired"));
    } else {
      if (onlineProd.is_paid !== "1") {
        //Is not already paid: redirect to the payment page
        history.push({
          pathname: "/member/onlinepayment",
          state: { product: onlineProd },
        });
      }
    }
  };

  return (
    <>
      {(onLineProd.entry_on_time === "1" ||
        (onLineProd.entry_on_time === "0" && onLineProd.is_paid === "1")) && (
        <tr
          key={"olptr_" + onLineProd.id}
          data={JSON.stringify(onLineProd)}
          onClick={(e) => handleRowClick(e)}
        >
          <td className="notices col-sm-4">
            {onLineProd.is_paid === "1" ? (
              <i className="fas notice-new">{t("common.paid")}</i>
            ) : (
              "＜" +
              (onLineProd.price === "0"
                ? t("common.free")
                : "￥" + onLineProd.price) +
              "＞　"
            )}
            {" " + onLineProd.product_title}
            <br />
            {t("onlineProducts.eventDate")}:{" "}
            {t("dates.fullDateJap", {
              date: new Date(onLineProd.event_from_daytime),
            }) +
              " " +
              t("dates.time", {
                date: new Date(onLineProd.event_from_daytime),
              }) +
              "～" +
              (onLineProd.event_end_daytime
                ? t("dates.time", {
                    date: new Date(onLineProd.event_end_daytime),
                  })
                : "")}
            {onLineProd.is_paid !== "1" && (
              <>
                <br />
                {t("onlineProducts.entryDate")}:{" "}
                {t("dates.fullDateJap", {
                  date: new Date(onLineProd.entry_from_daytime),
                }) +
                  " ～ " +
                  t("dates.fullDateJap", {
                    date: new Date(onLineProd.entry_end_daytime),
                  })}
              </>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default OnlineCourseElement;
