import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import VideoCard from "../../components/VideoCard";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetAllVideos } from "../../redux/useVideosRedux";
import SystemErrorPanel from "../main/SystemErrorPanel";
import useAuth from "../../auth/useAuth";

const RideVideosViewMore = () => {
  const { t, i18n } = useTranslation();
  const videos = useSelector((state) => state.videos.rideVideos);
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user && auth.user.member_auth3_flag === "1") {
      const searchConds = {
        product_type: "2",
        hide_flag: "0",
        language: i18n.language,
        user_id: auth?.user?.user_id,
      };
      dispatch(GetAllVideos(searchConds));
    }
  }, [dispatch, i18n.language, auth?.user]);

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content">
        <div className="row">
          <div className="col-md-10">
            <div className="movie">{t("rideVideos.title")}</div>
          </div>
        </div>

        <div className="row">
          {videos &&
            videos.map((v, i) => (
              <div className="col-md-3" key={"video_" + i}>
                <VideoCard video={v} />
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RideVideosViewMore;
