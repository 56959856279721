import React from "react";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useTranslation } from "react-i18next";
import SystemErrorPanel from "../main/SystemErrorPanel";
import { useLocation } from "react-router-dom";

export default function OnlinePaid() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content unauthorized-video-warn">
        <div className="row">
          <div className="col-md-10">
            <div className="alert alert-success">
              <strong>{t("payment.paySuccessTitle")}</strong>{" "}
              {t("payment.paySuccessProductOnlineCourse", {
                courseTitle: location.state.product.product_title,
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
