import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NoticeDialog from "./NoticeDialog";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UpdateNoticeAsRead } from "../../redux/useInitDataRedux";
import LoadingCom from "../../components/LoadingCom";

const Notices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initData = useSelector((state) => state.initData);
  const [showNoticeDialog, setShowNoticeDialog] = useState(false);
  const [notice, setNotice] = useState(null);

  const handleRowClick = (e) => {
    const rowNotice = JSON.parse(e.currentTarget.getAttribute("data"));
    setNotice(rowNotice);
    setShowNoticeDialog(true);

    //Dispatch to set the notice as read
    if (initData.user_id && rowNotice.new_flag === "1") {
      dispatch(UpdateNoticeAsRead(rowNotice));
    }
  };

  return (
    <>
      <NoticeDialog
        show={showNoticeDialog}
        handleClose={setShowNoticeDialog}
        notice={notice}
      />
      <div className="row">
        <div className="col-md-6">
          <div className="h-text-dark">{t("notices.title")}</div>
        </div>
        {initData.notices?.length > 0 && (
          <div className="col-md-6">
            <Link to="/member/notices" className="view_more">
              {t("common.view_more")}
            </Link>
          </div>
        )}
      </div>

      {initData.isLoading ? (
        <LoadingCom />
      ) : initData.notices && initData.notices.length > 0 ? (
        <Table table-borderless={"true"} hover size="sm">
          <tbody>
            {initData.notices?.map((n) => (
              <tr
                key={"notice_mod_" + n.id}
                data={JSON.stringify(n)}
                onClick={(e) => handleRowClick(e)}
              >
                <td className="notices col-sm-4">
                  {n.user_id && n.new_flag === "1" ? (
                    <i className="fas fa-bell notice-new">{" New"}</i>
                  ) : (
                    <></>
                  )}{" "}
                  {t("dates.fullDateShort", {
                    date: new Date(n.release_from_day),
                  })}
                </td>
                <td className="notices col-sm-8">{n.title}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="col-md-12">
          <div className="alert alert-info">{t("notices.noData")}</div>
        </div>
      )}
    </>
  );
};

export default Notices;
