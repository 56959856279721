import { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { searchAnyProductByProductId, ClearCurrentProduct } from "../redux/useVideosRedux";
import { useTranslation } from "react-i18next";
import LoadingCom from "../components/LoadingCom"

//購入動画
export default function OnlinePaymentRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  const location = useLocation();
  const [productChecked, setProductChecked] = useState(false);
  const product = useSelector((state) => state.videos.currentProduct);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (auth.isAuthenticated && location.state.product.is_paid === ""){
      const searchConds = {
        product_id: location.state.product.product_id,
        language: i18n.language,
      };
      dispatch(searchAnyProductByProductId(searchConds));
    }
  }, [dispatch, auth, location.state.product, i18n]);

  useEffect(() => {
    if (product){
      //setProductIsPaid(product.is_paid === "1");
      setProductChecked(true);
    }
  }, [product]);

  useEffect(() => {
    //Clear the product on component unmount
    return () => {
      dispatch(ClearCurrentProduct());
    };
  }, [dispatch]);

  return (
    <Route {...rest}>
    {
      (auth.isAuthenticated) ? 
      (
        (location.state.product.is_paid === "" && !productChecked)?
        <LoadingCom />
        :
        (location.state.product.is_paid === "0")?
        (<Component />)
        :
        (location.state.product.is_paid === "" && productChecked && product.is_paid === "0")?
        (<Component />)
        :
        (<Redirect to={{ pathname: "/member/OnlinePaid" , state: {product: product, from: location  }}} />)
      ) 
      : 
      (
        <Redirect to={{ pathname: "/member/login" , state: { from: location  }}} />
      )
}

      
    </Route>
   
  );
}