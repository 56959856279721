import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"
//import { useTranslation } from "react-i18next";

//constants
const initialData = {
    loginUser: null,
    error: null
}

//types
const GET_LOGIN_USER_SUCCESS = "GET_LOGIN_USER_SUCCESS"
const GET_LOGIN_USER_FAIL = "GET_LOGIN_USER_FAIL"
const GET_LOGOUT_USER_SUCCESS = "GET_LOGOUT_USER_SUCCESS"
const GET_LOGOUT_USER_FAIL = "GET_LOGOUT_USER_FAIL"
const GET_ERR_500 = "GET_ERR_500"
const REFRESH_LOGIN_USER = "REFRESH_LOGIN_USER"

//reducer
export default function authReducer(state = initialData, action){

    switch(action.type){
        case GET_LOGIN_USER_SUCCESS:
            return {...state, loginUser: action.payload}
        case GET_LOGIN_USER_FAIL:
            return {loginUser: null, error: action.payload}
        case GET_LOGOUT_USER_SUCCESS:
            return {loginUser: null, error: null}
        case GET_LOGOUT_USER_FAIL:
            return {loginUser: null, error: action.payload}
        case GET_ERR_500:
            return {loginUser: null, error: action.payload}
        case REFRESH_LOGIN_USER:
            let refreshUser = state.loginUser;
            refreshUser.user = action.payload;
            return {...state, loginUser: refreshUser}
        default:
            return state
    }
}


//actions
export const login = (values, setErrorHandler, setLoginUser) => async (dispatch, getState) => {
   
    var ctx = {
        data: [
            {
            api: "T0010UsersService.login",
            mode: 0,
            input:{
                id: values.user_id,
                password: values.user_password,
                savelogin: (values.savelogin?"1":"0"),
                user_type: "1"
            }}],
    };

    dispatch({type: CommonTypes.CLEAR_ERROR});
    
    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl, ctx)
    .then((response) => {
        
        if (response.data.data[0].output?.user){
            dispatch({
                type: GET_LOGIN_USER_SUCCESS,
                payload: response.data.data[0].output
            })
            setLoginUser(response.data.data[0].output, values);

            if (response.data.data[0].output.user.subscriptionStatus === "ERROR"){
                dispatch({
                    type: CommonTypes.GET_DATA_ERROR,
                    payload: SystemConsts.subscription_validation_error 
                }) 
            }
        }
        else{
            let dispatchError = "";
            if (response.data.error){
                dispatchError = response.data.errorMessage;
                setErrorHandler({ hasError: true, incorrectUser: false, message: dispatchError });
            }
            else{
                dispatchError = "loginidOrPasswordIncorrect";
                setErrorHandler({ hasError: true, incorrectUser: true, message: dispatchError });
            }
            dispatch({
                type: GET_LOGIN_USER_FAIL,
                payload: dispatchError
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err);
        setErrorHandler({ hasError: true, incorrectUser: false, message: err.message });
        dispatch({
            type: GET_ERR_500,
            payload: err.message
        })
    });
} 

export const checkSessionToken = (userId, token, setErrorHandler) => async (dispatch, getState) => {
    
    var ctx = {
        data: [
            {
            api: "T0010UsersService.CheckSesionToken",
            mode: 0,
            input:{
                id: userId,
                token: token,
                user_type: "1"
            }}],
    };
    
    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output?.user){
                dispatch({
                    type: GET_LOGIN_USER_SUCCESS,
                    payload: response.data.data[0].output
                })

                if (response.data.data[0].output.user.subscriptionStatus === "ERROR"){
                    dispatch({
                        type: CommonTypes.GET_DATA_ERROR,
                        payload: SystemConsts.subscription_validation_error 
                    }) 
                }
            }
            else{
                localStorage.removeItem("user");
                setErrorHandler({hasError: true, incorrectUser: true, message: "incorrect token" });
                dispatch({
                    type: GET_LOGIN_USER_FAIL,
                    payload: "incorrect token"
                })
            }

        })
        .catch((err ) => {
            //Error
            setErrorHandler({ hasError: true, incorrectUser: false, message: err.message });
            console.log("Axios err: ", err);
            dispatch({
                type: GET_ERR_500,
                payload: err.message
            })
        });
} 

export const checkSavedToken = (userId, token, setErrorHandler) => async (dispatch, getState) => {
    
    var ctx = {
        data: [
            {
            api: "T0010UsersService.CheckToken",
            mode: 0,
            input:{
                id: userId,
                token: token,
                user_type: "1"
            }}],
    };
    
    dispatch({type: CommonTypes.CLEAR_ERROR});
    
    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output?.user){
                dispatch({
                    type: GET_LOGIN_USER_SUCCESS,
                    payload: response.data.data[0].output
                })

                if (response.data.data[0].output.user.subscriptionStatus === "ERROR"){
                    dispatch({
                        type: CommonTypes.GET_DATA_ERROR,
                        payload: SystemConsts.subscription_validation_error  //t("login.subscriptionCheckError")
                    }) 
                }
            }
            else{
                localStorage.removeItem("user");
                setErrorHandler({hasError: true, incorrectUser: true, message: "incorrect token" });
                dispatch({
                    type: GET_LOGIN_USER_FAIL,
                    payload: "incorrect token"
                })
            }

        })
        .catch((err ) => {
            //Error
            setErrorHandler({ hasError: true, incorrectUser: false, message: err.message });
            console.log("Axios err: ", err);
            dispatch({
                type: GET_ERR_500,
                payload: err.message
            })
        });
} 

export const logout = (user) => async (dispatch, getState) => {
   
    var ctx = {
        data: [
            {
            api: "T0010UsersService.logout",
            mode: 0,
            input:user}],
    };

    dispatch({type: CommonTypes.CLEAR_ERROR});
    
    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl,ctx)
        .then((response) => {
            if (!response.data.error){
                dispatch({
                    type: GET_LOGOUT_USER_SUCCESS
                })
            }
            else{
                dispatch({
                    type: GET_LOGOUT_USER_FAIL,
                    payload: response.data.errorMessage
                })
            }
            

        })
        .catch((err ) => {
            //Error
            console.log("Axios err: ", err);
            dispatch({
                type: GET_LOGOUT_USER_FAIL,
                payload: err.message
            })
        })
        .finally( () => {
            //history.push("/");
            //Redirect("/");
        })
    
} 

export const signin = (values, setErrorHandler, handleSuccess) => async (dispatch, getState) => {
   
    var ctx = {
        data: [
            {
            api: "T0010UsersService.signin",
            mode: 0,
            input:values
            }],
    };

    dispatch({type: CommonTypes.CLEAR_ERROR});
    
    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl, ctx)
    .then((response) => {
        
        if (response.data.data[0].output?.user){
            dispatch({
                type: GET_LOGIN_USER_SUCCESS,
                payload: response.data.data[0].output
            })
            handleSuccess(response.data.data[0].output);
        }
        else{
            let dispatchError = "";
            if (response.data.error){
                dispatchError = response.data.errorMessage;
            }
            else if (response.data.data[0].errors && response.data.data[0].errors[0].messageKey){
                dispatchError = response.data.data[0].errors[0].messageKey;
            }
            else{
                dispatchError = "System Error";
            }
            setErrorHandler(dispatchError);
            dispatch({
                type: GET_LOGIN_USER_FAIL,
                payload: dispatchError
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err);
        setErrorHandler(err.message);
        dispatch({
            type: GET_ERR_500,
            payload: err.message
        })
    });
} 

export const RefreshLoginUserData = (loginUser) => (dispatch, getState) => {
    dispatch({
        type: REFRESH_LOGIN_USER,
        payload: loginUser
    })
}

export const UploadUserVideoAuthorization = (inputData) => async (dispatch, getState) => {
   
    var ctx = {
        data: [
            {
            api: "T0010UsersService.UploadUserVideoAuthorization",
            mode: 0,
            input: inputData
            }],
    };

    dispatch({type: CommonTypes.CLEAR_ERROR});

    await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
    .then((response) => {
        
        if (response.data.data[0].output){
            dispatch({
                type: REFRESH_LOGIN_USER,
                payload: response.data.data[0].output.user
            })
        }
        else{
            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: "System Error"
            })
        }
    })
    .catch((err ) => {
        //Error
        console.log("Axios err: ", err)
        let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

        dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: errorMessage
        })
    })
    .finally(() => {
        //dispatch(dataLoading(false));
    });
} 