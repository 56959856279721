import React, { useEffect } from "react";
import TextboxCom from "../../components/TextboxCom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PayProductButtonCom from "../../components/stripe/PayProductButtonCom";
import { SearchSubscriptionPrice } from "../../redux/usePaymentRedux";
import {
  SetFreePayment,
  ClearPaidOnlineProd,
} from "../../redux/useOnlineProdRedux";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../components/Logo";
import { useHistory } from "react-router";

const Payment = (props) => {
  const initData = useSelector((state) => state.initData);
  const onlineFreeProd = useSelector(
    (state) => state.onLineProds.freePaymentProductId
  );
  const { t } = useTranslation();
  const location = useLocation();
  const product = location.state.product;
  const dispatch = useDispatch();
  const subscriptionPrice = useSelector(
    (store) => store.payment.subscriptionPrice
  );
  const history = useHistory();

  useEffect(() => {
    //Search for the subscription price
    if (product && product.product_id === "0") {
      dispatch(SearchSubscriptionPrice(product));
    }
    dispatch(ClearPaidOnlineProd());
  }, [dispatch, product]);

  useEffect(() => {
    if (onlineFreeProd) {
      history.push("/member/paysuccess/" + onlineFreeProd.id);
    }
  }, [onlineFreeProd, history]);

  const handleFreePayment = () => {
    dispatch(SetFreePayment(product));
  };

  return (
    <div className="body-payment">
      <div className="text-center">
        <form className="form-payment">
          {initData.logo && <Logo logo={initData.logo} />}
          <br />
          <span className="payment-header-text">{initData.title}</span>
          <h1 className="h3 mb-3 font-weight-normal title-payment">
            {product?.price !== "0"
              ? t("payment.title")
              : t("payment.register")}
          </h1>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="product">{t("payment.product")}</label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                name="product"
                disabled
                value={product.product_title}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="description">{t("payment.description")}</label>
            </div>
            <div className="col-md-8">
              <TextboxCom
                name="description"
                disabled
                value={product.product_note}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 label">
              <label htmlFor="price">{t("payment.price")}</label>
            </div>
            <div className="col-md-8">
              {product.product_id === "0" && !subscriptionPrice ? (
                <div className="spinner-border text-muted"></div>
              ) : (
                <TextboxCom
                  name="price"
                  disabled
                  value={
                    product.product_id !== "0"
                      ? "￥" + product.price
                      : t("payment.subscriptionPrice", {
                          price: "￥" + subscriptionPrice,
                        })
                  }
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {product?.price !== "0" ? (
                <PayProductButtonCom
                  className="btn btn-lg btn-primary btn-block"
                  label={t("payment.payButtonText")}
                  product_id={product.product_id}
                  language="ja"
                />
              ) : (
                <button
                  role="link"
                  type="button"
                  onClick={handleFreePayment}
                  className="btn btn-lg btn-primary btn-block"
                >
                  {t("payment.freePaymentButtonText")}
                </button>
              )}
            </div>
          </div>

          <div className="logout-advice">
            <Link to="/member/">{t("common.backtotop")}</Link>
          </div>

          <p className="mt-5 mb-3 text-muted">{initData.titleFooter}</p>
        </form>
      </div>
    </div>
  );
};

export default Payment;
