import React from "react";

const Error500 = () => {
  return (
    <div className="error-contain">
      <div className="error-text">ERROR 500</div>
    </div>
  );
};

export default Error500;
