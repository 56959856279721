import React, { useState, useEffect } from "react";
import SystemConsts from "../../common/constants/SystemConsts";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import Client from "../../redux/Client";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PayProductButtonCom = (props) => {
  const initData = useSelector((state) => state.initData);

  if (initData.stripe_public_key == null) {
    return null;
  } else {
    const stripePromise = loadStripe(initData?.stripe_public_key);

    return (
      <Elements stripe={stripePromise}>
        <CheckoutButton {...props} />
      </Elements>
    );
  }
};

const CheckoutButton = (props) => {
  const stripe = useStripe();
  // stripe.redirectToCheckout
  const [sessionId, setSessionId] = useState(null);
  const [disabledFlag, setDisabledFlag] = useState(true);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    Client.postApi([
      {
        api: "stripeService.createPayProductSession",
        input: {
          product_id: props.product_id,
          language: props.language,
          success_url: SystemConsts.Pay.SuccessUrl,
          fail_url: SystemConsts.Pay.FailUrl,
        },
      },
    ]).then((data) => {
      if (!data[0].output) {
        setError("System Error");
      }
      setSessionId(data[0].output);
      setDisabledFlag(false);
    });
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Create a new Checkout Session using the server-side endpoint you
    // created in step 3.
    return stripe.redirectToCheckout({ sessionId: sessionId });
  };

  return (
    <>
      {error !== "" ? (
        <div className="alert alert-danger alert-dismissible fade show">
          <strong>{error}</strong>
        </div>
      ) : !sessionId ? (
        <button className={props.className} disabled>
          <span className="spinner-border spinner-border-sm"></span>
          {t("common.loading")}
        </button>
      ) : (
        <button
          role="link"
          type="button"
          onClick={handleSubmit}
          disabled={disabledFlag}
          className={props.className}
        >
          {props.label}
        </button>
      )}
    </>
  );
};

export default PayProductButtonCom;
