import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingCom from "../../components/LoadingCom";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import OnlineCourseElement from "./OnlineCourseElement";

const OnlineCourses = () => {
  const initData = useSelector((state) => state.initData);
  const { t } = useTranslation();

  const handleJoinZoom = (meetingId) => {
    window.open(meetingId, "_blank");
  };

  return (
    <>
      {initData.currentOnlineProd &&
        initData.currentOnlineProd.length > 0 &&
        initData.currentOnlineProd?.map((curOnLineProd, i) => (
          <>
            {i === 0 ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="h-text-dark">
                    {t("onlineProducts.seminar")}
                  </div>
                </div>
              </div>
            ) : (
              <br />
            )}

            <div className="row">
              <div className="col-md-12">{curOnLineProd.product_title}</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {t("onlineProducts.eventDate")}:{" "}
                {t("dates.fullDateJap", {
                  date: new Date(curOnLineProd.event_from_daytime),
                }) +
                  " " +
                  t("dates.time", {
                    date: new Date(curOnLineProd.event_from_daytime),
                  }) +
                  "～" +
                  (curOnLineProd.event_end_daytime
                    ? t("dates.time", {
                        date: new Date(curOnLineProd.event_end_daytime),
                      })
                    : "")}
              </div>

              <div className="col-md-12">
                <button
                  className="btn btn-zoom"
                  onClick={() => handleJoinZoom(curOnLineProd.meeting_id)}
                >
                  {t("onlineProducts.joinZoom")}
                </button>
              </div>
            </div>
          </>
        ))}

      <div className="row">
        <div className="col-md-7">
          <div className="h-text-dark">{t("onlineProducts.planTitle")}</div>
        </div>
        {initData.onlineProds?.length > 0 && (
          <div className="col-md-5">
            <Link to="/member/onlineproducts" className="view_more">
              {t("common.view_more")}
            </Link>
          </div>
        )}
      </div>

      {initData.isLoading ? (
        <LoadingCom />
      ) : initData.onlineProds?.length > 0 ? (
        <Table table-borderless={"true"} hover size="sm">
          <tbody>
            {initData.onlineProds?.map((onLineProd, i) => (
              <OnlineCourseElement onLineProd={onLineProd} key={"olpe_" + i} />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="col-md-12">
          <div className="alert alert-info">
            {t("onlineProducts.noCourses")}
          </div>
        </div>
      )}
    </>
  );
};

export default OnlineCourses;
