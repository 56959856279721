import React, { useState } from "react";
import { useHistory } from "react-router";
import useAuth from "../../auth/useAuth";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextboxCom from "../../components/TextboxCom";
import { useSelector } from "react-redux";
import Logo from "../../components/Logo";
import SystemConsts from "../../common/constants/SystemConsts";

const Login = () => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const initData = useSelector((state) => state.initData);
  const intialValues = { user_id: "", user_password: "", rememberLogin: true };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [loginError, setLoginError] = useState({
    hasError: false,
    message: "",
    incorrectUser: false,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    let errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      auth.login(
        formValues["user_id"],
        formValues["user_password"],
        formValues["rememberLogin"],
        handleLoginSuccess,
        setLoginError
      );
    }
  };

  let { from } = location.state || { from: { pathname: "/member/" } };
  const handleLoginSuccess = (userInfo) => {
    //if success back to Top or Reset Password
    if (userInfo.user.password_initial_flag === "1") {
      history.push("/member/resetpw");
    } else {
      //history.push("/");
      history.replace(from);
    }
  };

  //input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const validate = (values) => {
    let errors = {};

    if (!values.user_id) {
      errors.user_id = t("login.errors.user_required");
    }
    if (!values.user_password) {
      errors.user_password = t("login.errors.password_required");
    }

    setFormErrors(errors);
    return errors;
  };

  return (
    <div className="body-signin">
      <div className="text-center">
        <form className="form-signin" onSubmit={(e) => handleLogin(e)}>
          {initData.logo && <Logo logo={initData.logo} />}
          <span className="signin-header-text">{initData.title}</span>
          <h1 className="h3 mb-3 font-weight-normal">{t("login.login")}</h1>
          <label htmlFor="inputId" className="sr-only">
            {t("login.id")}
          </label>

          <TextboxCom
            name="user_id"
            placeholder={t("login.id")}
            autoFocus
            value={formValues.user_id}
            onChange={handleChange}
            formerrors={formErrors}
          />
          <label htmlFor="inputPassword" className="sr-only">
            {t("login.password")}
          </label>
          <TextboxCom
            type="password"
            name="user_password"
            placeholder={t("login.password")}
            value={formValues.user_password}
            onChange={handleChange}
            formerrors={formErrors}
          />
          <div className="checkbox mb-3">
            <input
              type="checkbox"
              id="rememberLogin"
              name="rememberLogin"
              value="rememberLogin"
              checked={formValues.rememberLogin}
              onChange={handleChecked}
            />
            <label htmlFor="rememberLogin">&nbsp;{t("login.rememberme")}</label>
          </div>

          {(loginError.hasError || loginError.incorrectUser) && (
            <div className="alert alert-warning">
              <h6 style={{ color: "red" }}>
                {loginError.message ===
                SystemConsts.errorLoginidOrPasswordIncorrect
                  ? t("login.errors.loginidOrPasswordIncorrect")
                  : loginError.message}
              </h6>
            </div>
          )}

          <button className="btn btn-lg btn-primary btn-block" type="submit">
            {t("login.signin")}
          </button>

          <div className="backToTop-advice">
            <Link to="/member/newmember">{t("login.newMember")}</Link>
          </div>

          <div className="backToTop-advice">
            <Link to="/member/">{t("common.backtotop")}</Link>
          </div>

          <p className="mt-5 mb-3 text-muted">{initData.titleFooter}</p>
        </form>
      </div>
    </div>
  );
};

export default Login;
