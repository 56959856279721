import React, { useEffect, useState } from "react";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import SystemErrorPanel from "../main/SystemErrorPanel";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { GetAllNotices } from "../../redux/useNoticesRedux";
import { Table } from "react-bootstrap";
import NoticeDialog from "../../modules/notices/NoticeDialog";
import { UpdateNoticeAsRead } from "../../redux/useNoticesRedux";

const NoticesViewMore = () => {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const dispatch = useDispatch();
  const notices = useSelector((state) => state.notices.notices);
  const [showNoticeDialog, setShowNoticeDialog] = useState(false);
  const [notice, setNotice] = useState(null);

  useEffect(() => {
    let user_id = auth?.user?.user_id;

    const searchConds = {
      language: i18n.language,
      user_id: user_id,
    };
    dispatch(GetAllNotices(searchConds));
  }, [dispatch, auth?.user?.user_id, i18n.language]);

  const handleRowClick = (e) => {
    const rowNotice = JSON.parse(e.currentTarget.getAttribute("data"));
    setNotice(rowNotice);
    setShowNoticeDialog(true);

    //Dispatch to set the notice as read
    let user_id = auth?.user?.user_id;
    if (user_id && rowNotice.new_flag === "1") {
      dispatch(UpdateNoticeAsRead(rowNotice));
    }
  };

  return (
    <>
      <NoticeDialog
        show={showNoticeDialog}
        handleClose={setShowNoticeDialog}
        notice={notice}
      />
      <SystemErrorPanel />
      <Header />
      <Menu />

      <div className="container viewmore-content">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="h-text-dark">{t("notices.title")}</div>
              </div>
            </div>

            <Table table-borderless={"true"} hover size="sm">
              <tbody>
                {notices?.map((n) => (
                  <tr
                    key={"notice_" + n.id}
                    data={JSON.stringify(n)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    <td className="notices col-sm-3">
                      {n.user_id && n.new_flag === "1" ? (
                        <i className="fas fa-bell notice-new">{" New"}</i>
                      ) : (
                        <></>
                      )}{" "}
                      {t("dates.fullDateShort", {
                        date: new Date(n.release_from_day),
                      })}
                    </td>
                    <td className="notices col-sm-9">{n.title}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NoticesViewMore;
