import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    result: null,
    error: null
}

//types
const GET_PASSWORD_CHANGE = "GET_PASSWORD_CHANGE"
const GET_PASSWORD_CHANGE_ERROR = "GET_PASSWORD_CHANGE_ERROR"
const SET_INITIAL_PW = "SET_INITIAL_PW"

//reducer
export default function passwordReducer(state = initialData, action){

    switch(action.type){
        case GET_PASSWORD_CHANGE:
            return {...state, result: action.payload, error: null}
        case GET_PASSWORD_CHANGE_ERROR:
            return {...state, result: null, error: action.payload}
        case SET_INITIAL_PW:
                return {...state, result: null, error: null}
        default:
            return state
    }
}

//loading dispatcher
export const loading = (data) => {
    return {
        type: CommonTypes.DATA_LOADING,
        payload: data,
    }
}

//actions
export const SaveNewPassword = (inputData, url) => async (dispatch, getState) => {

    var ctx = {
        data: [
          {
            api: "T0010UsersService.resetPassword",
            mode: 0,
            input: inputData,
          },
        ],
      };

      dispatch({type: CommonTypes.CLEAR_ERROR});

      await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output){
                
                
                dispatch({
                    type: GET_PASSWORD_CHANGE,
                    payload: response.data.data[0].output
                })
            }
            else{
                let errorMessage = "System Error";
                if (response.data.data[0].errors && response.data.data[0].errors[0].messageKey){
                    errorMessage = response.data.data[0].errors[0].messageKey;
                }

                dispatch({
                    type: GET_PASSWORD_CHANGE_ERROR,
                    payload: errorMessage
                })
            }

        })
        .catch((err ) => {
            //Error
            console.log("Axios err: ", err)
            let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: errorMessage
            })
        })
        .finally(() => {
            
        });
    
} 

export const ClearState = () => (dispatch, getState) => {
    dispatch({type: SET_INITIAL_PW});
    dispatch({type: CommonTypes.CLEAR_ERROR});
}