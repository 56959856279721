import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const SerieLink = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleVideoClick = (video) => {
    let pathName = "";

    if (video.product_type === "99") {
      //Online course
      handleOnlineCourseClick(video);
      return;
    } else if (video.product_type === "3" || video.price === "0") {
      //Free video
      pathName = "/member/playfreevideo";
    } else {
      if (video.product_type === "1" && video.is_archive !== "1") {
        //Uploaded video (配信動画)
        pathName = "/member/playuploadvideo";
      } else {
        //その他（決済が必要）
        pathName = "/member/playpayvideo";
      }
    }

    history.push({
      pathname: pathName,
      state: { product: video },
    });
  };

  const handleOnlineCourseClick = (onlineProd) => {
    if (onlineProd.is_paid === "1") {
      //The course is paid
      alert(t("onlineProducts.courseIsPaid"));
    } else if (onlineProd.entry_on_time === "0") {
      //The entry date is expired
      alert(t("onlineProducts.courseIsExpired"));
    } else {
      if (onlineProd.is_paid !== "1") {
        //Is not already paid: redirect to the payment page
        history.push({
          pathname: "/member/onlinepayment",
          state: { product: onlineProd },
        });
      }
    }
  };

  const getPriceLabel = (video) => {
    var label = " (";
    if (video.is_paid === "1") label += t("common.paid");
    else if (video.price === "0") label += t("common.free");
    else if (video.product_type === "1" && video.is_archive === "0")
      label += t("videos.title");
    else label += "￥" + props.serieVideo.price;

    label += ")";

    return label;
  };

  return (
    <span
      className="series_more"
      to="/"
      onClick={() => handleVideoClick(props.serieVideo)}
    >
      {props.serieVideo.product_title.concat(getPriceLabel(props.serieVideo))}
    </span>
  );
};

export default SerieLink;
