import React from "react";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import SystemErrorPanel from "../main/SystemErrorPanel";
import { useTranslation } from "react-i18next";

const UnauthorizedVideo = () => {
  const { t } = useTranslation();
  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content unauthorized-video-warn">
        <div className="row">
          <div className="col-md-10">
            <div className="alert alert-warning">
              <strong>{t("common.warning") + ": "}</strong>{" "}
              {t("playContent.unauthorized_warning")}
              <div className="container p-3 my-3 bg-white text-black border">
                {t("playContent.unauthorized_explanation")}
              </div>
              <div className="text-end">
                <input
                  className="btn btn-primary"
                  type="button"
                  value={t("common.back")}
                  onClick={handleBack}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UnauthorizedVideo;
