import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SystemConsts from "../../common/constants/SystemConsts";
import useAuth from "../../auth/useAuth";
import { doThumbnail } from "../../common/utils/Utils";

const url =
  SystemConsts.Api.Host +
  SystemConsts.ContextPath +
  SystemConsts.Api.DownloadUrl +
  "?path=p_";
const PayMark = (props) => {
  const auth = useAuth();
  const { t } = useTranslation();

  if (
    props.product.product_type === "2" ||
    props.product.product_type === "99" ||
    props.product.is_archive === "1"
  ) {
    //2:騎乗動画 or OnlineCourse or Archive
    if (props.product.is_paid === "1") {
      return (
        <div className="video_img-top-right-no-free">{t("common.paid")}</div>
      );
    } else {
      return (
        <div className="video_img-top-right-no-free">
          &#165;{props.product.price}
        </div>
      );
    }
  } else {
    if (auth.isAuthenticated) {
      if (
        auth.user?.subscriptionStatus !== SystemConsts.subscriptionSuccessStatus
      ) {
        return (
          <div className="video_img-top-right-no-free">
            return <div className="video_img-top-right-no-free">&#165;</div>;
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <div className="video_img-top-right-no-free">&#165;</div>;
    }
  }
};
const FreeMark = () => {
  const { t } = useTranslation();
  return <div className="video_img-top-right-free">{t("common.free")}</div>;
};

const ProductCard = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const Thumbnail = (props) => {
    if (props.product?.product_id) {
      if (props.product?.product_type !== "99") {
        return (
          <img
            className="video-img"
            src={
              url +
              props.product.product_id +
              ".jpg&name=p_" +
              props.product.product_id +
              ".jpg "
            }
            alt={props.product.thumbnail_name}
            onClick={() =>
              handleImgClick(props.product, props.product.is_archive === "1")
            }
            onError={(e) => handleImgError(e)}
          />
        );
      } else {
        return (
          <img
            className="video-img"
            src={
              url +
              props.product.product_id +
              ".jpg&name=p_" +
              props.product.product_id +
              ".jpg "
            }
            alt=""
            onClick={() => handleOnlineCourseClick(props.product)}
          />
        );
      }
    }
  };

  const handleImgError = (e) => {
    doThumbnail(e, props, handleImgClick);
    return true;
  };

  const handleImgClick = (product, isArchive) => {
    let pathName = "";

    if (product.product_type === "3" || product.price === "0") {
      //Free video
      pathName = "/member/playfreevideo";
    } else {
      if (product.product_type === "1" && !isArchive) {
        //Uploaded video (配信動画)
        pathName = "/member/playuploadvideo";
      } else {
        //その他（決済が必要）
        pathName = "/member/playpayvideo";
      }
    }

    history.push({
      pathname: pathName,
      state: { product: product },
    });
  };

  const handleOnlineCourseClick = (onlineProd) => {
    if (onlineProd.is_paid === "1") {
      //The course is paid
      alert(t("onlineProducts.courseIsPaid"));
    } else if (onlineProd.entry_on_time === "0") {
      //The entry date is expired
      alert(t("onlineProducts.courseIsExpired"));
    } else {
      if (onlineProd.is_paid !== "1") {
        //Is not already paid: redirect to the payment page
        history.push({
          pathname: "/member/onlinepayment",
          state: { product: onlineProd },
        });
      }
    }
  };

  return (
    <div className="video_img-container img-wrapper">
      <Thumbnail
        product={props.product}
        isArchive={props.product.is_archive === "1"}
      />
      {parseInt(props.product.price) > 0 ? (
        <PayMark
          product={props.product}
          isArchive={props.product.is_archive === "1"}
        />
      ) : (
        <FreeMark />
      )}
    </div>
  );
};

export default ProductCard;
