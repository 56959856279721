import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import NewMemberRegister from "./pages/newMemberRegister/NewMemberRegister";
import Main from "./pages/main/Main";
import generateStore from "./redux/store";
import { Provider } from "react-redux";
import AuthProvider from "./auth/AuthProvider";
import InitialPwRoute from "./Routes/InitialPwRoute";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import MainRoute from "./Routes/MainRoute";
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import Payment from "./pages/payment/Payment";
import { useDispatch } from "react-redux";
import { SearchInitData } from "./redux/useInitDataRedux";
import { useTranslation } from "react-i18next";
import Notices from "./pages/notices/NoticesViewMore";
import UploadedVideosViewMore from "./pages/uploadedVideos/UploadedVideosViewMore";
import useAuth from "./auth/useAuth";
import PlayFreeVideo from "./pages/playvideo/PlayFreeVideo";
import UploadVideoRoute from "./Routes/UploadVideoRoute";
import PlayPayVideo from "./pages/playvideo/PlayPayVideo";
import UnauthorizedVideo from "./pages/playvideo/UnauthorizedVideo";
import PaySuccess from "./pages/payment/PaySuccess";
import PayFail from "./pages/payment/PayFail";
import FreeVideosViewMore from "./pages/freeVideos/FreeVideosViewMore";
import RideVideosViewMore from "./pages/rideVideos/RideVideosViewMore";
import ArchivesViewMore from "./pages/archives/ArchivesViewMore";
import PayVideoRoute from "./Routes/PayVideoRoute";
import OnLineCoursesViewMore from "./pages/onLineCourses/OnLineCoursesViewMore";
import SeriesViewMore from "./pages/series/SeriesViewMore";
import PaymentRoute from "./Routes/PaymentRoute";
import OnlinePaymentRoute from "./Routes/OnlinePaymentRoute";
import OnlinePaid from "./pages/payment/OnlinePaid";

const AppWrapper = (props) => {
  const store = generateStore();

  return (
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  );
};

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth = useAuth();

  //Get initial data
  useEffect(() => {
    if (!auth.isAuthenticating()) {
      let language = i18n.language || window.localStorage.i18nextLng || "";
      dispatch(
        SearchInitData({ language: language, user_id: auth?.user?.user_id })
      );
    }
  }, [dispatch, auth, i18n.language]);

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/member/login" component={Login} />
        <PublicRoute
          exact
          path="/member/newmember"
          component={NewMemberRegister}
        />
        <InitialPwRoute
          exact
          path="/member/resetpw"
          component={ResetPassword}
        />
        <Route exact path="/member/notices" component={Notices} />

        {/* VIEW MORE */}
        <Route
          exact
          path="/member/uploadedvideos"
          component={UploadedVideosViewMore}
        />
        <Route exact path="/member/freevideos" component={FreeVideosViewMore} />
        <Route
          exact
          path="/member/archivevideos"
          component={ArchivesViewMore}
        />
        <PrivateRoute
          exact
          path="/member/ridevideos"
          component={RideVideosViewMore}
        />
        <Route
          exact
          path="/member/onlineproducts"
          component={OnLineCoursesViewMore}
        />
        <Route exact path="/member/series" component={SeriesViewMore} />
        {/* VIEW MORE END*/}

        {/* VIDEO */}
        <Route exact path="/member/playfreevideo" component={PlayFreeVideo} />
        <Route
          exact
          path="/member/unauthorizedvideo"
          component={UnauthorizedVideo}
        />
        <UploadVideoRoute
          exact
          path="/member/playuploadvideo"
          component={PlayPayVideo}
        />
        <PayVideoRoute
          exact
          path="/member/playpayvideo"
          component={PlayPayVideo}
        />
        {/* VIDEO END */}

        {/* PAYMENT */}
        <PaymentRoute exact path="/member/payment" component={Payment} />
        <OnlinePaymentRoute
          exact
          path="/member/onlinepayment"
          component={Payment}
        />
        <Route path="/member/paysuccess/:id" component={PaySuccess} />
        <Route path="/member/payfail/:id" component={PayFail} />
        <Route path="/member/payfail" component={PayFail} />
        <Route path="/member/OnlinePaid" component={OnlinePaid} />
        {/* PAYMENT END */}

        <MainRoute path="/member/" component={Main} />
        <MainRoute path="/member/home" component={Main} />
      </Switch>
    </Router>
  );
}

export default AppWrapper;
