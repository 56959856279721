import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetSeries } from "../../redux/useSeriesRedux";
import SystemErrorPanel from "../main/SystemErrorPanel";
import LoadingCom from "../../components/LoadingCom";
import SystemConsts from "../../common/constants/SystemConsts";
import SerieLink from "../../modules/series/SerieLink";
import { doThumbnail } from "../../common/utils/Utils";

const SeriesViewMore = () => {
  const { t, i18n } = useTranslation();
  const series = useSelector((state) => state.series.series);
  const dispatch = useDispatch();
  const url =
    SystemConsts.Api.Host +
    SystemConsts.ContextPath +
    SystemConsts.Api.DownloadUrl +
    "?path=p_";

  const handleImgError = (e, product) => {
    var props = { video: { product_title: product.product_title } };
    doThumbnail(e, props, null);
    return true;
  };

  const SerieMark = (serie) => {
    const { t } = useTranslation();
    return (
      <div className="video_img-serie">
        {t("seriesVideos.countVideos", {
          count: serie.serie.length,
        })}
      </div>
    );
  };

  useEffect(() => {
    const searchConds = {
      language: i18n.language,
    };
    dispatch(GetSeries(searchConds));
  }, [dispatch, i18n.language]);

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />

      <div className="container series_more_contain">
        <div className="row">
          <div className="col-md-10">
            <div className="series-movie">{t("seriesVideos.title")}</div>
          </div>
        </div>

        {!series ? (
          <LoadingCom />
        ) : series.length > 0 ? (
          series.map((serie, i) => (
            <div className="bg-dark-image section-bold" key={"srsm_" + i}>
              <div className="row" key={"srs_" + i}>
                <div className="col-md-5">
                  <div className="card mb-2 shadow-sm">
                    <img
                      className="serievideo-img"
                      src={
                        url +
                        serie[0].product_id +
                        ".jpg&name=p_" +
                        serie[0].product_id +
                        ".jpg "
                      }
                      alt={serie[0].thumbnail_name}
                      onError={(e) => handleImgError(e, serie[0])}
                    />
                    <SerieMark serie={serie} />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="text-light-mb">{serie[0].series_title}</div>
                  </div>
                  <div className="row">
                    <div className="text-light-s">{serie[0].series_note}</div>
                  </div>
                  <div className="bg-gray-square1">
                    <div className="row row-series">
                      {serie.map((serieVideo, iVideo) => (
                        <div className="col-md-４" key={"srv_" + iVideo}>
                          <SerieLink serieVideo={serieVideo} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {i < series.length - 1 && (
                <div className="row" key={"srsdv_" + i}>
                  <div className="col-md-12">
                    <div className="b-solid-blue"></div>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <div className="alert alert-info">{t("common.noVideos")}</div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default SeriesViewMore;
