import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import VideoCard from "../../components/VideoCard";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetAllVideos } from "../../redux/useVideosRedux";
import SystemErrorPanel from "../main/SystemErrorPanel";

const UploadedVideosViewMore = () => {
  const { t, i18n } = useTranslation();
  const videos = useSelector((state) => state.videos.uploadVideos);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchConds = {
      product_type: "1",
      hide_flag: "0",
      language: i18n.language,
    };
    dispatch(GetAllVideos(searchConds));
  }, [dispatch, i18n.language]);

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content">
        <div className="row">
          <div className="col-md-10">
            <div className="movie">{t("videos.title")}</div>
          </div>
        </div>

        <div className="row">
          {videos &&
            videos.map((v, i) => (
              <div className="col-md-3" key={"video_" + i}>
                <VideoCard video={v} />
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UploadedVideosViewMore;
