import React from "react";

const Logo = (props) => {
  const handleImgError = (e) => {
    e.target.src = "/member/assets/image/Thumbnail.svg";
    e.target.xlinkHref = null;
    e.target.onerror = null;
    return true;
  };

  return (
    /*
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="59"
      height="61"
      viewBox="0 0 59 61"
    >
      {" "}*/
    <img
      id="logo"
      width="59"
      height="61"
      alt=""
      //xlinkHref={"data:img/png;base64," + props.logo}
      src={"data:image/svg+xml;base64," + props.logo}
      onError={(e) => handleImgError(e)}
    />
    /*</svg>*/
  );
};

export default Logo;
