import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingCom from "../../components/LoadingCom";
import ProductCard from "./ProductCard";

const RecommendedContent = () => {
  const initData = useSelector((state) => state.initData);
  const { t } = useTranslation();

  return (
    <>
      {initData.isLoading ? (
        <LoadingCom />
      ) : (
        initData.recommendedProduct && (
          <section className="bg-dark-image section-bold">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="medal">{t("recommendedProduct.title")}</div>
                  </div>
                  <div className="row">
                    <div className="text-light-mb">
                      {initData.recommendedProduct.product_title}
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-light-s">
                      {initData.recommendedProduct.product_note}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card mb-5 shadow-sm">
                    <ProductCard product={initData.recommendedProduct} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default RecommendedContent;
