import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../modules/footer/Footer";
import Header from "../../modules/header/Header";
import Menu from "../../modules/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetAllOnlineProds } from "../../redux/useOnlineProdRedux";
import SystemErrorPanel from "../main/SystemErrorPanel";
import { Table } from "react-bootstrap";
import OnlineCourseElement from "../../modules/onlineCourses/OnlineCourseElement";

const OnLineCoursesViewMore = () => {
  const { t, i18n } = useTranslation();
  const onLineProds = useSelector((state) => state.onLineProds.onlineProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchConds = {
      language: i18n.language,
    };
    dispatch(GetAllOnlineProds(searchConds));
  }, [dispatch, i18n.language]);

  return (
    <>
      <SystemErrorPanel />
      <Header />
      <Menu />
      <div className="container viewmore-content">
        <div className="row">
          <div className="col-md-10">
            <div className="movie">{t("onlineProducts.planTitle")}</div>
          </div>
        </div>

        <Table table-borderless={"true"} hover size="sm">
          <tbody>
            {onLineProds?.map((onLineProd, i) => (
              <OnlineCourseElement
                onLineProd={onLineProd}
                key={"olpevm_" + i}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <Footer />
    </>
  );
};

export default OnLineCoursesViewMore;
