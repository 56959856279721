import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SystemConsts from "../../common/constants/SystemConsts";
import { doThumbnail } from "../../common/utils/Utils";
import LoadingCom from "../../components/LoadingCom";
import SerieLink from "./SerieLink";

const Series = () => {
  const { t } = useTranslation();
  const initData = useSelector((state) => state.initData);

  const url =
    SystemConsts.Api.Host +
    SystemConsts.ContextPath +
    SystemConsts.Api.DownloadUrl +
    "?path=p_";

  const handleImgError = (e, product) => {
    var props = { video: { product_title: product.product_title } };
    doThumbnail(e, props, null);
    return true;
  };

  const SerieMark = (serie) => {
    const { t } = useTranslation();
    return (
      <div className="video_img-serie">
        {t("seriesVideos.countVideos", {
          count: serie.serie.length,
        })}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <div className="series-movie">{t("seriesVideos.title")}</div>
        </div>

        {initData.seriesVideos?.length > 0 && (
          <div className="col-md-2">
            <Link to="/member/series" className="view_more_w">
              {t("common.view_more")}
            </Link>
          </div>
        )}
      </div>

      {initData.isLoading ? (
        <LoadingCom />
      ) : initData.seriesVideos?.length > 0 ? (
        initData.seriesVideos.map((serie, i) => (
          <div key={"srsm_" + i}>
            <div className="row bg-black-opacity" key={"srs_" + i}>
              <div className="col-md-5">
                <div className="card mb-2 shadow-sm">
                  <img
                    className="serievideo-img"
                    src={
                      url +
                      serie[0].product_id +
                      ".jpg&name=p_" +
                      serie[0].product_id +
                      ".jpg "
                    }
                    alt={serie[0].thumbnail_name}
                    onError={(e) => handleImgError(e, serie[0])}
                  />
                  <SerieMark serie={serie} />
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="text-light-mb">{serie[0].series_title}</div>
                </div>
                <div className="row">
                  <div className="text-light-s">{serie[0].series_note}</div>
                </div>
                <div className="bg-gray-square1">
                  <div className="row row-series">
                    {serie.map((serieVideo, iVideo) => (
                      <div className="col-md-４" key={"srv_" + iVideo}>
                        <SerieLink serieVideo={serieVideo} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {i < initData.seriesVideos.length - 1 && (
              <div className="row bg-black-opacity" key={"srsdv_" + i}>
                <div className="col-md-12">
                  <div className="b-solid-blue"></div>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="col-md-12">
          <div className="alert alert-info">{t("common.noVideos")}</div>
        </div>
      )}
    </div>
  );
};

export default Series;
